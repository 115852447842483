import React, { useEffect, useState } from "react";
import styles from "./firstStep.module.scss";
import { useSelector } from "react-redux";
import { credentialSelectors } from "../../../../store/credentials";
import { useCasesSelectors } from "../../../../store/useCases";
import StepsContent from "../../../../components/elements/stepsContent/stepsContent";
import {
  deleteSessionStorageData,
  setSessionStorageData,
} from "../../../../services/sessionStorageService";
import { getUCReceiveCredentialsStepData } from "../../../../store/useCases/useCasesSelectors";
import { gaQRIcons } from "../../../../data/images";
import { IssuanceQR } from "../../../../components/elements/gatacaQRs/IssuanceQR";
interface IFirstStepProps {
  manageSubStepChange: () => void;
  goToNextSection: () => void;
}

const FirstStep: React.FC<IFirstStepProps> = (props) => {
  const { manageSubStepChange, goToNextSection } = props;

  // States
  const [loadingContent, setLoadingContent] = useState(false);

  // Selectors
  let processId = useSelector(credentialSelectors.getProcessId);
  const selectedUCFirstStepId = useSelector(
    useCasesSelectors.getSelectedUseCaseStepsId
  )?.first;
  const stepsData = useSelector(getUCReceiveCredentialsStepData);

  // States
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    deleteSessionStorageData("ga__scredSkipped");
  }, []);

  // Functions
  const renderGetIssuanceQR = () => {
    return (
      <div>
        <IssuanceQR
          sessionId={processId}
          goNextStep={manageSubStepChange}
          qrIcon={gaQRIcons.gataca}
          setLoadingContent={setLoadingContent}
          UCStepId={selectedUCFirstStepId}></IssuanceQR>
      </div>
    );
  };

  const skipSection = () => {
    setSessionStorageData("ga__scredSkipped", "true");
    goToNextSection();
  };

  return (
    <>
      <StepsContent
        data={stepsData.first}
        QRComponent={renderGetIssuanceQR}
        buttonAction={skipSection}
        classNameBgContainer={styles?.bgContainer}
        classNameQRContainer={styles?.QrContainer}
        classNameMainContainer={styles?.mainContainerBg}
      />
    </>
  );
};

export default FirstStep;
