import React, { useState } from "react";
import { credentialSelectors } from "../../../../store/credentials";
import StepsContent from "../../../../components/elements/stepsContent/stepsContent";
import { useSelector } from "react-redux";
import { useCasesSelectors } from "../../../../store/useCases";
import { getUCRequestCredentialStepData } from "../../../../store/useCases/useCasesSelectors";
import { IssuanceQR } from "../../../../components/elements/gatacaQRs/IssuanceQR";

interface IFirstStepProps {
  manageSubStepChange: () => void;
}

const FirstStep: React.FC<IFirstStepProps> = (props) => {
  const { manageSubStepChange } = props;

  // States
  const [loadingContent, setLoadingContent] = useState(false);

  // Selectors
  let processId = useSelector(credentialSelectors.getProcessId);
  const selectedUCThirdStepId = useSelector(
    useCasesSelectors.getSelectedUseCaseStepsId
  )?.third;
  const customQRIcon = useSelector(
    useCasesSelectors.getSelectedUseCaseCustomQRIcon
  );
  const stepsData = useSelector(getUCRequestCredentialStepData);

  // Functions
  const renderGetIssuanceQR = () => {
    return (
      <div>
        <IssuanceQR
          sessionId={processId}
          goNextStep={manageSubStepChange}
          qrIcon={customQRIcon}
          getIfCredIsSentPolling
          setLoadingContent={setLoadingContent}
          UCStepId={selectedUCThirdStepId}></IssuanceQR>
      </div>
    );
  };

  return (
    <>
      <StepsContent
        data={!loadingContent ? stepsData.first : stepsData.second}
        QRComponent={renderGetIssuanceQR}
      />
    </>
  );
};

export default FirstStep;
