import React from "react";
import styles from "./firstSection.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import GaImage from "../../../../components/atoms/Images/gaImage";
import { gaImages } from "../../../../data/images";
import PurpleButton from "../../../../components/atoms/buttons/purple/PurpleButton";

const FirstSection: React.FC = () => {
  const { t } = useTranslation();

  let useCasesSection: HTMLOptionElement | null | undefined;

  React.useEffect(() => {
    useCasesSection = document
      ? (document?.getElementById("useCases") as HTMLOptionElement)
      : undefined;
  });

  const scrollToSection = () => {
    useCasesSection?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className={`${styles?.firstSection__container}`}>
        <div className={styles?.rightBackground}>
          <GaImage image={gaImages.logoHomeBg} />
        </div>
        <div className={`${styles?.leftColumn} `}>
          <h1 className={` ${cx("marginBottom32")}`}>{t("home.homeTitle")}</h1>
          <p>{t("home.homeDescription")}</p>
          <PurpleButton
            label={t("home.getStarted")}
            action={() => scrollToSection()}
          />
        </div>
        <div className={`${styles?.rightColumn}`}>
          <div className={`${styles?.mobileCredentialsImage__container}`}>
            <GaImage image={gaImages.mobileCredentials} />
          </div>
          <div className={`${styles?.tryDemoUseCasesImage__container}`}>
            <button onClick={() => scrollToSection()}>
              <GaImage image={gaImages.tryDemoUseCases} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstSection;
