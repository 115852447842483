import {
  IRequestItem,
  RESULT_ISSUANCE_STATUS,
} from "../../interfaces/interfaces";

export interface ICreateIssuanceSessionAction {
  type: CredentialActionTypes.CREATE_ISSUANCE_SESSION;
  payload: {
    useCaseStepId: string;
  };
}

export interface ICreateVerificationSessionAction {
  type: CredentialActionTypes.CREATE_VERIFICATION_SESSION;
  payload: {
    useCaseStepId: string;
  };
}

export interface ICreatedSessionAction {
  type: CredentialActionTypes.CREATED_SESSION;
  payload: {
    processId: string;
    error: any;
  };
}

export interface ICreatedCredentialAction {
  type: CredentialActionTypes.CREATED_CREDENTIAL;
  payload: {
    credentials: IRequestItem;
  };
}

export interface IGetCredentialAction {
  type: CredentialActionTypes.GET_SESSION;
  payload: {
    processId: string;
    useCaseStepId: string;
  };
}

export interface IGetIfCredHasIsSentAction {
  type: CredentialActionTypes.GET_IS_CREDENTIAL_SENT;
  payload: {
    useCaseStepId: string;
    processId: string;
  };
}
export interface IGetIfCredHasIsSentSuccessAction {
  type: CredentialActionTypes.GET_IS_CREDENTIAL_SENT_SUCCESS;
  payload: {
    result: RESULT_ISSUANCE_STATUS | undefined;
  };
}
export interface IGetIfCredHasIsSentFailedAction {
  type: CredentialActionTypes.GET_IS_CREDENTIAL_SENT_FAILED;
}

export interface IStartIsCredentialSentPollingAction {
  type: CredentialActionTypes.START_IS_CREDENTIAL_SENT_POLLING;
  payload: {
    interval: number;
    fetch: any;
  };
}

export interface IStopIsCredentialSentPollingAction {
  type: CredentialActionTypes.STOP_IS_CREDENTIAL_SENT_POLLING;
}

export interface IClearStateAction {
  type: CredentialActionTypes.CLEAR_STATE;
}

export enum CredentialActionTypes {
  // Types for first QR to issue requested credential
  CREATE_ISSUANCE_SESSION = "issuance_session/create_new",
  CREATE_VERIFICATION_SESSION = "verification_session/create_new",
  CREATED_VERIFICATION_SESSION = "verification_session/created",
  CREATED_SESSION = "session/created",
  CREATED_CREDENTIAL = "issuance_session/ready",
  CREATED_CREDENTIAL_FAILED = "issuance_session/failed",
  GET_SESSION = "session/get",
  CLEAR_STATE = "credentials/clear",
  // Types to know when cred is requested
  GET_IS_CREDENTIAL_SENT = "credential/get_if_sent",
  GET_IS_CREDENTIAL_SENT_SUCCESS = "credential/get_if_sent_success",
  GET_IS_CREDENTIAL_SENT_FAILED = "credential/get_if_sent_failed",
  START_IS_CREDENTIAL_SENT_POLLING = "credential/get_if_sent_start_polling",
  STOP_IS_CREDENTIAL_SENT_POLLING = "credential/get_if_sent_stop_polling",
}

export type CredentialAction =
  | ICreateIssuanceSessionAction
  | ICreateVerificationSessionAction
  | ICreatedSessionAction
  | IGetCredentialAction
  | ICreatedCredentialAction
  | IGetIfCredHasIsSentAction
  | IGetIfCredHasIsSentSuccessAction
  | IGetIfCredHasIsSentFailedAction
  | IStartIsCredentialSentPollingAction
  | IStopIsCredentialSentPollingAction
  | IClearStateAction;

export interface ICredentialState {
  processId: string;
  request: IRequestItem;
  result: RESULT_ISSUANCE_STATUS;
  credIssuedID?: string;
  isFetching: boolean;
  polling: boolean;
  pollingId: any;
  credentialSentResult: RESULT_ISSUANCE_STATUS | undefined;
}
