import React, { useEffect } from "react";
import styles from "./downloadWallet.module.scss";
import cx from "classnames";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../../store/navigation/navigationActions";
import DownloadAppContent from "../components/downloadAppContent";
import { gaImages } from "../../../data/images";
import { gatacaContact } from "../../../data/globalData";
import { useTranslation } from "react-i18next";
import BackButton from "../../../components/atoms/buttons/back/BackButton";
import ChevronLeftSVG from "../../../assets/icons/ChevronLeftSVG";
import { useDispatch, useSelector } from "react-redux";
import { useCasesSelectors } from "../../../store/useCases";
import { setActiveUseCaseStep } from "../../../store/useCases/useCasesActions";
import { setSessionStorageData } from "../../../services/sessionStorageService";
import { applicableLabels } from "../../../i18n";

const DownloadWallet: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Selectors
  const selectedUCPath = useSelector(useCasesSelectors.getSelectedUseCasePath);
  const selectedUCSteps = useSelector(
    useCasesSelectors.getSelectedUseCaseSteps
  );

  // Functions
  const goToNextSection = (url: string) => {
    setSessionStorageData("ga__subStp", "0");
    selectedUCSteps && dispatch(setActiveUseCaseStep(selectedUCSteps[0]?.id));
    dispatch(navigateToAction(url));
  };

  const goToPreviousStep = () => {
    setSessionStorageData("ga__subStp", "0");
    dispatch(navigateToAction("/demo"));
  };

  useEffect(() => {
    // TODO: LOCK BACK BUTTON IN THIS VIEW?
    dispatch(setSelectedRoute("/download-wallet"));
  }, []);

  const scrollToTop = () => {
    let rootContainer = document ? document?.getElementById("root") : undefined;
    rootContainer?.scrollTo({ top: 0 });
  };
  scrollToTop();

  return (
    <>
      <div className={`${styles.generalContainer}`}>
        <div
          className={`${styles?.downloadWalletContainer} ${cx("gridWidth stepMaxWidth")} `}>
          <BackButton
            label={t("backButton")}
            action={() => goToPreviousStep()}
            IconComponent={<ChevronLeftSVG />}
          />
          <DownloadAppContent
            title={t("downloadWallet.title")}
            descriptionParagraph={t("downloadWallet.descriptionParagraph")}
            buttonSkip={{
              label: t("downloadWallet.buttonSkip"),
              action: () =>
                goToNextSection(`/demo/${selectedUCPath}/receive-credentials`),
            }}
            QRimage={gaImages.QrAppStore}
            imageRightSide={gaImages.mobile}
            helpLinkUrl={gatacaContact}
            helpLinklabel={t("downloadWallet.help")}
          />
        </div>
      </div>
    </>
  );
};

export default DownloadWallet;
