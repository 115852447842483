import { RootState } from "../../store/indexReducers";

export const getProcessId = (state: RootState) =>
  state.credentialReducer.processId;
export const getRequest = (state: RootState) => state.credentialReducer.request;
export const getResult = (state: RootState) => state.credentialReducer.result;
export const getIsPolling = (state: RootState) =>
  state.credentialReducer.polling;
export const getResultSentCredPolling = (state: RootState) =>
  state.credentialReducer.credentialSentResult;
