import { IAlertState, AlertActions, AlertActionTypes } from "./alertTypes";

const sessionInitialState: IAlertState = {
  alert: undefined,
};

export const alertReducer = (
  state = sessionInitialState,
  action: AlertActions
) => {
  switch (action.type) {
    case AlertActionTypes.SHOW_ALERT:
      const {
        title,
        kind,
        subtitle,
        trailingIcon,
        closeButton,
        status,
        duration,
      } = action?.payload?.alert;
      return {
        ...state,
        alert: {
          title,
          kind,
          subtitle,
          trailingIcon,
          closeButton,
          status,
          duration,
        },
      };
    case AlertActionTypes.HIDE_ALERT:
      return {
        ...state,
        alert: undefined,
      };
    default:
      return state;
  }
};
