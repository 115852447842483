import { StepsContentModel } from "../../interfaces/interfaces";
import FirstStep from "./processSteps/FirstStep/FirstStep";
import SecondStep from "./processSteps/SecondStep/SecondStep";
import ThirdStep from "./processSteps/ThirdStep/ThirdStep";

// Steps
export const viewSteps = [
  {
    id: "1",

    content: (
      manageSubStepChange: () => void,
      goToNextSection: () => void,
      goToPreviousStep: () => void
    ) => (
      <FirstStep
        manageSubStepChange={manageSubStepChange}
        goToNextSection={goToNextSection}
      />
    ),
  },
  {
    id: "2",
    content: (
      manageSubStepChange: () => void,
      goToNextSection: () => void,
      goToPreviousStep: () => void
    ) => (
      <SecondStep
        manageSubStepChange={manageSubStepChange}
        goToNextSection={goToNextSection}
        goToPreviousStep={goToPreviousStep}
      />
    ),
  },
  {
    id: "3",
    content: (
      manageSubStepChange: () => void,
      goToNextSection: () => void,
      goToPreviousStep: () => void
    ) => <ThirdStep goToNextSection={goToNextSection} />,
  },
];
