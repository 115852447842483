import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Transition from "react-transition-group/Transition";
import styles from "./alertNotification.module.scss";
import { hideAlert } from "../../../../store/alerts/alertActions";
import { getAlert } from "../../../../store/alerts/alertSelectors";
import Alert from "../Alert";
const duration = 300;

const AlertNotification = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let defaultTimeOut = 3000;
  const alert = useSelector(getAlert);

  if (alert) {
    !alert?.closeButton
      ? setTimeout(() => {
          dispatch(hideAlert());
        }, props.timeout || defaultTimeOut)
      : null;
  }

  return (
    <Transition
      className={`${props?.className && props.className}`}
      in={alert ? true : false}
      timeout={
        alert?.duration && alert?.duration > 0 ? alert?.duration : duration
      }
      unmountOnExit>
      {() => (
        <div className={`${styles.gaAlertNotification}`}>
          {alert && (
            <Alert
              classname={` ${props?.className && props.className}`}
              kind={alert?.kind}
              trailingIcon={alert?.trailingIcon}
              title={alert?.title || ""}
              subtitle={alert?.subtitle ? t(alert?.subtitle) : ""}
              closeButton={!!alert?.closeButton}
            />
          )}
        </div>
      )}
    </Transition>
  );
};

export default AlertNotification;
