// NAVIGATION
export interface ISetActiveRoute {
  type: NavigationActionsTypes.SET_ACTIVE_ROUTE;
  payload: {
    route: string;
  };
}

export interface INavigate {
  type: NavigationActionsTypes.NAVIGATE;
  path?: string;
}

export type NavigationActions = ISetActiveRoute;

export enum NavigationActionsTypes {
  SET_ACTIVE_ROUTE = "navigation/set_active_route",
  NAVIGATE = "navigation/navigate",
}

export interface IScafoldingState {
  activeRoute: string;
}
