import {
  ALERT_ICONS,
  ALERT_TYPES,
  IAlertItem,
} from "../store/alerts/alertTypes";

// --------------------------------------------------
// Error alerts data
// --------------------------------------------------

const issueCredentialErrorAlert: IAlertItem = {
  kind: ALERT_TYPES.ERROR,
  subtitle: "errors.problemDetectedScanAgain",
  trailingIcon: ALERT_ICONS?.ALERT,
  closeButton: true,
  status: undefined,
  duration: undefined,
};

export const errorAlerts = {
  issueCredential: issueCredentialErrorAlert,
};

// --------------------------------------------------
// Info alerts data
// --------------------------------------------------

const infoQRScanedBefore: IAlertItem = {
  kind: ALERT_TYPES.INFO,
  subtitle: "general.notSharingRequiredAgain",
  trailingIcon: ALERT_ICONS.INFO,
};

export const infoAlerts = {
  qrScanedBefore: infoQRScanedBefore,
};
