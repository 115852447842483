import {
  IScafoldingState,
  UseCasesActions,
  UseCasesActionsTypes,
} from "./useCasesTypes";

// NAVIGATION
const initialUseCasesState: IScafoldingState = {
  selectedUseCase: undefined,
  activeUseCaseStep: undefined,
};

export function useCasesReducer(
  state = initialUseCasesState,
  action: UseCasesActions
): IScafoldingState {
  switch (action.type) {
    case UseCasesActionsTypes.SET_SELECTED_USE_CASE:
      return {
        ...state,
        selectedUseCase: action.payload?.id,
      };
    case UseCasesActionsTypes.SET_ACTIVE_USE_CASE_STEP:
      return {
        ...state,
        activeUseCaseStep: action.payload?.id,
      };
    default:
      return state;
  }
}
