import React from "react";

type InfoIconProps = {
  size?: number;
  color?: string;
  className?: string;
};

const InfoIcon: React.FC<InfoIconProps> = React.memo((props) => {
  const { size, color, className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className && className}
      width={size || "32"}
      height={size || "32"}
      viewBox="0 0 32 32"
      fill="none">
      <path
        d="M16.0003 29.3333C23.3641 29.3333 29.3337 23.3638 29.3337 16C29.3337 8.63617 23.3641 2.66663 16.0003 2.66663C8.63653 2.66663 2.66699 8.63616 2.66699 16C2.66699 23.3638 8.63652 29.3333 16.0003 29.3333Z"
        stroke={color || "currentColor" || "#1E1E20"}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21.3333L16 16"
        stroke={color || "currentColor" || "#1E1E20"}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 10.6666L16.0125 10.6666"
        stroke={color || "currentColor" || "#1E1E20"}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default InfoIcon;
