import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./thirdStep.module.scss";
import StepsContent from "../../../../components/elements/stepsContent/stepsContent";
import { useDispatch, useSelector } from "react-redux";
import { getUCIdentityVerificationStepData } from "../../../../store/useCases/useCasesSelectors";
import { hideAlert } from "../../../../store/alerts/alertActions";

interface IThirdStepProps {
  manageSubStepChange: () => void;
  goToNextSection: () => void;
}

const ThirdStep: React.FC<IThirdStepProps> = (props) => {
  const { manageSubStepChange } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const stepsData = useSelector(getUCIdentityVerificationStepData);

  // Effects
  useEffect(() => {
    dispatch(hideAlert());
  });

  return (
    <>
      <StepsContent
        data={stepsData.third}
        buttonAction={manageSubStepChange}
        classNameRightSideContainer={styles?.rightSideContainer}
      />
    </>
  );
};

export default ThirdStep;
