import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./secondStep.module.scss";
import {
  getIsCredentialSent,
  startIsCredentialSentPolling,
  stopIsCredentialSentPolling,
} from "../../../../store/credentials/credentialActions";
import { useDispatch, useSelector } from "react-redux";
import { credentialSelectors } from "../../../../store/credentials";
import { useCasesSelectors } from "../../../../store/useCases";
import StepsContent from "../../../../components/elements/stepsContent/stepsContent";
import { verifiableIdIcons } from "../../../../data/images";
import VerifiableId from "../../../../components/elements/verifiableId/VerifiableId";
import {
  getUCReceiveCredentialsStepData,
  selectedUseCaseId,
} from "../../../../store/useCases/useCasesSelectors";
import { RESULT_ISSUANCE_STATUS } from "../../../../interfaces/interfaces";
import { showAlert } from "../../../../store/alerts/alertActions";
import { errorAlerts } from "../../../../data/alertsData";

interface ISecondStepProps {
  manageSubStepChange: () => void;
  goToNextSection: () => void;
  goToPreviousStep: () => void;
}

const SecondStep: React.FC<ISecondStepProps> = (props) => {
  const { manageSubStepChange, goToPreviousStep } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultPollingInterval = 3000;

  // Selectors
  const sentCredPollingResult = useSelector(
    credentialSelectors.getResultSentCredPolling
  );
  const stepsData = useSelector(getUCReceiveCredentialsStepData);
  const selectedUseCaseIdStep = useSelector(selectedUseCaseId);
  const processId = useSelector(credentialSelectors.getProcessId);
  const selectedUCSecondStepId = useSelector(
    useCasesSelectors.getSelectedUseCaseStepsId
  )?.first;
  const credWasSuccessfulySent =
    sentCredPollingResult === RESULT_ISSUANCE_STATUS.SUCCESS ||
    sentCredPollingResult === RESULT_ISSUANCE_STATUS.ISSUED;

  const credSendingError =
    sentCredPollingResult === RESULT_ISSUANCE_STATUS.FAILED;

  // Effects
  useEffect(() => {
    selectedUCSecondStepId &&
      processId &&
      pollCredIsSent(defaultPollingInterval);
  }, []);

  useEffect(() => {}, [selectedUCSecondStepId]);

  useEffect(() => {
    if (credWasSuccessfulySent) {
      dispatch(stopIsCredentialSentPolling());
      manageSubStepChange();
    } else if (credSendingError) {
      manageCredSendError();
    }
  }, [sentCredPollingResult]);

  // Functions
  const pollCredIsSent = (interval: number) => {
    const fetch = () =>
      dispatch(getIsCredentialSent(selectedUCSecondStepId, processId));
    dispatch(startIsCredentialSentPolling(interval, fetch));
  };

  const manageCredSendError = () => {
    dispatch(stopIsCredentialSentPolling());
    dispatch(showAlert(errorAlerts.issueCredential));
    goToPreviousStep();
  };

  return (
    <>
      <StepsContent
        data={stepsData.second}
        QRComponent={() => (
          <VerifiableId
            icon={
              selectedUseCaseIdStep === "eventAccess"
                ? verifiableIdIcons.event
                : selectedUseCaseIdStep === "obtainVerifiableID"
                  ? verifiableIdIcons.verifiableId
                  : verifiableIdIcons.iconID
            }
            category={t(
              "useCases." +
                selectedUseCaseIdStep +
                ".receiveCredentials.secondStep.category"
            )}
            issuer={t(
              "useCases." +
                selectedUseCaseIdStep +
                ".receiveCredentials.secondStep.issuer"
            )}
            idNumber={t(
              "useCases." +
                selectedUseCaseIdStep +
                ".receiveCredentials.secondStep.idNumber"
            )}
            loading={true}
          />
        )}
        classNameBgContainer={styles?.bgContainer}
        classNameQRContainer={styles?.QrContainer}
        classNameMainContainer={styles?.mainContainerBg}
      />
    </>
  );
};

export default SecondStep;
