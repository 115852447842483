import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RESULT_SESSION_STATUS } from "../../../interfaces/interfaces";
import dataService from "../../../services/dataService";
import styles from "./gatacaQR.module.scss";
import LoadingQR from "./components/loadingQR/LoadingQR";
import RetrievingInformation from "../retrievingInformation/RetrievingInformation";

type VerificationQRProps = {
  sessionId: any;
  UCStepId: string;
  qrIcon?: string;
  goNextStep: () => void;
  setLoadingContent: (x: boolean) => void;
};

export const VerificationQR: React.FC<VerificationQRProps> = (props) => {
  let { UCStepId, qrIcon, sessionId, goNextStep, setLoadingContent } = props;
  const qr = useRef();
  const { t } = useTranslation();
  const server = process.env.REACT_APP_DEMOLAND_HOST;
  const [loading, setLoading] = useState(false);
  const [loadingAfterRead, setLoadingAfterRead] = useState(false);

  // Effects
  useEffect(() => {
    if (qr != null && qr.current != null) {
      //@ts-ignore
      qr.current.createSession = createSessionId;
      //@ts-ignore
      qr.current.sessionId = sessionId;
      //@ts-ignore
      qr.current.checkStatus = checkStatus;
      //@ts-ignore
      qr.current.successCallback = gatacaVerificationSuccess;
      //@ts-ignore
      qr.current.errorCallback = gatacaVerificationError;
    }
  });

  // Actions
  const createSessionId = async () => {
    if (!sessionId) {
      setLoading(true);
    }
    let response = await dataService.verifierProcessCreateSession(UCStepId);
    const resStatus = response?.status;

    if (response && (resStatus === 200 || resStatus === 201)) {
      let data = await response?.json();
      sessionId = data?.identifier || sessionId;
      setLoading(false);

      sessionId = data?.identifier || sessionId;
      setLoading(false);

      return {
        sessionId: data?.identifier,
        authenticationRequest: data?.uri,
      };
    } else {
      return setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const checkStatus = async (id: string) => {
    try {
      let response = id?.length
        ? await dataService.verifierProcessCheckStatus(id, UCStepId)
        : null;
      let req = await response?.json();

      manageNotFailedStatus(req?.status);

      return response
        ? !req?.status || req?.status === RESULT_SESSION_STATUS.ONGOING
          ? {
              result: RESULT_SESSION_STATUS.ONGOING,
            }
          : req?.status === RESULT_SESSION_STATUS.FAILED
            ? {
                result: RESULT_SESSION_STATUS.FAILED,
              }
            : {
                result:
                  req?.status === RESULT_SESSION_STATUS.READED
                    ? RESULT_SESSION_STATUS.ONGOING
                    : RESULT_SESSION_STATUS.SUCCESS,
              }
        : {
            result: RESULT_SESSION_STATUS.FAILED,
          };
    } catch (e) {
      return {
        result: RESULT_SESSION_STATUS.FAILED,
      };
    }
  };

  const manageNotFailedStatus = (status: any) => {
    if (status == RESULT_SESSION_STATUS.READED) {
      setLoadingAfterRead(true);
      setLoadingContent(true);
    }
  };

  const gatacaVerificationSuccess = () => {
    setLoadingAfterRead(false);
    setLoadingContent(false);
    //@ts-ignore
    qr.current.stop();
    goNextStep();
  };

  const gatacaVerificationError = (error: any) => {
    setLoadingAfterRead(false);
    setLoadingContent(false);
    console.log("Verification Error", error);
  };

  return (
    <>
      <div className={styles.gatacaQR__container}>
        <>
          {
            // @ts-ignore
            <gataca-qr
              callback-server={server}
              ref={qr}
              hide-modal-texts="true"
              hide-modal-box-shadow="true"
              modal-width="323"
              qr-size="323"
              logo-src={qrIcon}
              polling-frequency="3"
              qr-role="credential"
              credentials-not-validated-label={t(
                "errors.errorDetectedTryAgain"
              )}
              v-2="true"
              session-id={sessionId}
            />
          }
          {loadingAfterRead ? (
            <RetrievingInformation className={styles.loadingQR__issuance} />
          ) : null}
          {loading ? (
            <LoadingQR className={styles.loadingQR__issuance} />
          ) : null}
        </>
      </div>
    </>
  );
};
