import React, { useEffect } from "react";
import styles from "./issuanceComplete.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCasesSelectors } from "../../../store/useCases";
import { setActiveUseCaseStep } from "../../../store/useCases/useCasesActions";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../../store/navigation/navigationActions";
import BackButton from "../../../components/atoms/buttons/back/BackButton";
import ChevronLeftSVG from "../../../assets/icons/ChevronLeftSVG";
import StepsContent from "../../../components/elements/stepsContent/stepsContent";
import {
  deleteSessionStorageData,
  setSessionStorageData,
} from "../../../services/sessionStorageService";
import {
  getUCLastStepStepData,
  selectedUseCaseId,
} from "../../../store/useCases/useCasesSelectors";
import {
  clearCredAndProcessState,
  stopIsCredentialSentPolling,
} from "../../../store/credentials/credentialActions";
import { hideAlert } from "../../../store/alerts/alertActions";

const IssuanceComplete: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Selectors
  const selectedUCSteps = useSelector(
    useCasesSelectors.getSelectedUseCaseSteps
  );
  const selectedUCPath = useSelector(useCasesSelectors.getSelectedUseCasePath);
  const stepsData = useSelector(getUCLastStepStepData);
  const selectedUseCaseIdStep = useSelector(selectedUseCaseId);

  // Effects
  useEffect(() => {
    deleteSessionStorageData("ga__process");
    dispatch(stopIsCredentialSentPolling());
    dispatch(clearCredAndProcessState());
    dispatch(hideAlert());
  });

  useEffect(() => {
    dispatch(setSelectedRoute(`/demo/${selectedUCPath}/issued-credentials`));

    selectedUCSteps?.length &&
      selectedUCSteps[3] &&
      dispatch(setActiveUseCaseStep(selectedUCSteps[3]?.id));
  }, [selectedUCSteps]);

  // Functions
  const goToFinish = () => {
    dispatch(navigateToAction(`/demo/${selectedUCPath}/finish`));
  };

  const goToPreviousStep = () => {
    selectedUCSteps?.length &&
      selectedUCSteps[2] &&
      (setSessionStorageData("ga__subStp", "0"),
      dispatch(
        navigateToAction(`/demo/${selectedUCPath}/${selectedUCSteps[2]?.id}`)
      ));
  };

  return (
    <>
      <div className={`${styles?.issuedCredential}`}>
        <div className={cx("gridWidth stepMaxWidth")}>
          <div className={cx("useCaseBackContainer")}>
            <BackButton
              label={t("backButton")}
              action={() => goToPreviousStep()}
              IconComponent={<ChevronLeftSVG />}
            />
          </div>
        </div>
        <StepsContent
          data={stepsData.issuanceComplete}
          buttonAction={goToFinish}
          classNameRightSideContainer={styles?.rightSideContainer}
        />
      </div>
    </>
  );
};

export default IssuanceComplete;
