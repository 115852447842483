import * as React from "react";
import styles from "./retrievingInformation.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import AnimatedLoader from "../../atoms/loaders/animatedLoader/AnimatedLoader";
import { LoaderSizes } from "../../../data/display";

type RetrievingInformationProps = {
  className?: string;
};

const RetrievingInformation: React.FC<RetrievingInformationProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className={`${styles?.card} ${cx(className && className)}`}>
        <div>
          <AnimatedLoader size={LoaderSizes.MD} />
        </div>
        <div>
          <p
            className={`${styles?.textBig} ${cx("neutral1000 marginBottom5")}`}>
            {t("general.retrievingInfo")}
          </p>
          <p className={`${styles?.textSmaller} ${cx("neutral700")}`}>
            {t("general.waitingForData")}
          </p>
        </div>
      </div>
    </>
  );
};

export default RetrievingInformation;
