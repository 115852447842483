import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./firstStep.module.scss";
import cx from "classnames";
import StepsContent from "../../../../components/elements/stepsContent/stepsContent";
import {
  getUCLastStepStepData,
  selectedUseCaseId,
} from "../../../../store/useCases/useCasesSelectors";
import { navigateToAction } from "../../../../store/navigation/navigationActions";
import { useCasesSelectors } from "../../../../store/useCases";
import { useTranslation } from "react-i18next";

interface IFirstStepProps {}

const FirstStep: React.FC<IFirstStepProps> = (props) => {
  const {} = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //Selectors
  const selectedUCSteps = useSelector(
    useCasesSelectors.getSelectedUseCaseSteps
  );
  const selectedUCPath = useSelector(useCasesSelectors.getSelectedUseCasePath);
  const stepsData = useSelector(getUCLastStepStepData);
  const selectedUseCaseIdStep = useSelector(selectedUseCaseId);

  // Functions
  const goToFinish = () => {
    dispatch(navigateToAction(`/demo/${selectedUCPath}/finish`));
  };

  return (
    <>
      <StepsContent
        data={stepsData.getAccess}
        buttonAction={goToFinish}
        classNameRightSideContainer={styles?.rightSideContainer}
      />
    </>
  );
};

export default FirstStep;
