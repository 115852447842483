import React from "react";
import { useTranslation } from "react-i18next";
import { ImageModel } from "../../../interfaces/interfaces";

type GaImageProps = {
  className?: string;
  image: ImageModel;
};

const GaImage: React.FC<GaImageProps> = React.memo((props) => {
  const { image, className } = props;
  const { t } = useTranslation();

  return (
    <img
      className={className}
      src={image?.src}
      alt={t(image?.alt ? image?.alt : "")}
    />
  );
});

export default GaImage;
