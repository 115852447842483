import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RESULT_ISSUANCE_STATUS } from "../../../interfaces/interfaces";
import dataService from "../../../services/dataService";
import { setSessionStorageData } from "../../../services/sessionStorageService";
import {
  createdProcess,
  getIsCredentialSent,
  startIsCredentialSentPolling,
  stopIsCredentialSentPolling,
} from "../../../store/credentials/credentialActions";
import styles from "./gatacaQR.module.scss";
import LoadingQR from "./components/loadingQR/LoadingQR";
import { hideAlert, showAlert } from "../../../store/alerts/alertActions";
import { errorAlerts } from "../../../data/alertsData";
import { credentialSelectors } from "../../../store/credentials";
import RetrievingInformation from "../retrievingInformation/RetrievingInformation";

type IssuanceQRProps = {
  sessionId: any;
  UCStepId: string;
  qrIcon?: string;
  getIfCredIsSentPolling?: boolean;
  goNextStep: () => void;
  setLoadingContent: (x: boolean) => void;
};

export const IssuanceQR: React.FC<IssuanceQRProps> = (props) => {
  let {
    UCStepId,
    sessionId,
    qrIcon,
    getIfCredIsSentPolling,
    goNextStep,
    setLoadingContent,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const qr = useRef();
  const server = process.env.REACT_APP_DEMOLAND_HOST;
  const pollingInterval = 3000;
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [loadingAfterRead, setLoadingAfterRead] = useState(false);

  // Selectors
  const isPolling = useSelector(credentialSelectors.getIsPolling);
  const sentCredPollingResult = useSelector(
    credentialSelectors.getResultSentCredPolling
  );
  const credWasSuccessfulySent =
    sentCredPollingResult === RESULT_ISSUANCE_STATUS.ISSUED;

  const credSendingError =
    sentCredPollingResult === RESULT_ISSUANCE_STATUS.FAILED;

  // Effects
  useEffect(() => {
    if (qr != null && qr.current != null) {
      //@ts-ignore
      qr.current.createSession = createSession;
      //@ts-ignore
      qr.current.checkStatus = checkStatus;
      //@ts-ignore
      qr.current.successCallback = credentialFilledSuccess;
      //@ts-ignore
      qr.current.errorCallback = credentialFilledError;
    }
  });

  useEffect(() => {
    if (credWasSuccessfulySent) {
      dispatch(stopIsCredentialSentPolling());
      //@ts-ignore
      qr?.current?.stop();
      setTimeout(() => {
        goNextStep();
        resetLoadingStates();
      }, 1);
    } else if (credSendingError) {
      manageCredSendError();
    }
  }, [sentCredPollingResult]);

  // Actions
  const createSession = async () => {
    dispatch(stopIsCredentialSentPolling());

    if (!sessionId) {
      setLoading(true);
    }

    let response = await dataService.issuanceProcessCreateSession(UCStepId);
    const resStatus = response?.status;

    if (response && (resStatus === 200 || resStatus === 201)) {
      let data = await response?.json();
      sessionId = data?.identifier || sessionId;

      resetLoadingStates();

      setTimeout(
        () => {
          dispatch(hideAlert());
        },
        !getIfCredIsSentPolling ? 3000 : 1000
      );

      return {
        sessionId: data?.identifier,
        authenticationRequest: data?.uri,
      };
    } else {
      return setTimeout(() => {
        resetLoadingStates();
      }, 1000);
    }
  };

  const checkStatus = async (id: string) => {
    let response = id?.length
      ? await dataService.issuanceProcessCheckStatus(id, UCStepId)
      : null;
    let req = await response?.json();

    manageNotFailedStatus(req?.status);

    return response
      ? !req?.status || req?.status === RESULT_ISSUANCE_STATUS.ONGOING
        ? {
            result: RESULT_ISSUANCE_STATUS.ONGOING,
          }
        : req?.status === RESULT_ISSUANCE_STATUS.FAILED
          ? {
              result: RESULT_ISSUANCE_STATUS.FAILED,
            }
          : {
              result:
                req?.status === RESULT_ISSUANCE_STATUS.READED
                  ? RESULT_ISSUANCE_STATUS.ONGOING
                  : issueCredential(id),
            }
      : {
          result: RESULT_ISSUANCE_STATUS.FAILED,
        };
  };

  const manageNotFailedStatus = (status: any) => {
    if (status == RESULT_ISSUANCE_STATUS.READED) {
      setLoadingAfterRead(true);
      setLoadingContent(true);
    }
  };
  const credentialFilledSuccess = (newdata: any, token: string) => {
    setLoadingAfterRead(false);
    setLoadingContent(false);
    //@ts-ignore
    qr.current.stop();
  };

  const credentialFilledError = (error: any) => {
    // TODO: Check with Tefy what to show if issuance fails
    setLoadingAfterRead(false);
    setLoadingContent(false);
    console.log("credential Filled Error", error);
  };

  const issueCredential = async (id: string) => {
    if ((getIfCredIsSentPolling && sentCredPollingResult) || isPolling) {
      return checkPollingStatus(id);
    }

    setLoaderStatesForIssueCred();

    let response = await dataService.issueCredential(UCStepId, id);
    let req = await response;

    let result = {
      result: RESULT_ISSUANCE_STATUS.ONGOING,
    };

    if (req && (!req?.status || req?.status === 0)) {
      resetLoadingStates();
      result = {
        result: RESULT_ISSUANCE_STATUS.ONGOING,
      };
    } else if (!req || req?.status === 2 || req?.status === 4) {
      //@ts-ignore
      qr?.current?.stop();
      dispatch(showAlert(errorAlerts.issueCredential));
      result = {
        result: RESULT_ISSUANCE_STATUS.FAILED,
      };

      resetLoadingStates();
    } else {
      getIfCredIsSentPolling ? startPolling(id) : goNext(id);
    }

    return result;
  };

  const checkPollingStatus = async (id: string) => {
    let result = {
      result: RESULT_ISSUANCE_STATUS.ONGOING,
    };

    if (credWasSuccessfulySent) {
      dispatch(stopIsCredentialSentPolling()),
        (result = {
          result: RESULT_ISSUANCE_STATUS.SUCCESS,
        });
    } else if (credSendingError) {
      manageCredSendError();
      result = {
        result: RESULT_ISSUANCE_STATUS.FAILED,
      };
    }

    return result;
  };

  const manageCredSendError = () => {
    dispatch(stopIsCredentialSentPolling());
    //@ts-ignore
    qr?.current?.stop();
    dispatch(showAlert(errorAlerts.issueCredential));
    resetLoadingStates();
  };

  const setLoaderStatesForIssueCred = () => {
    setLoadingText("general.loadingCredential");
    setLoading(true);
  };

  const goNext = (id: string) => {
    //@ts-ignore
    qr?.current?.stop();
    setSessionStorageData("ga__process", id);
    dispatch(createdProcess(id, ""));
    goNextStep();
    resetLoadingStates();
    return {
      result: RESULT_ISSUANCE_STATUS.SUCCESS,
    };
  };

  const startPolling = (id: string) => {
    const fetch = () => dispatch(getIsCredentialSent(UCStepId, id));
    dispatch(startIsCredentialSentPolling(pollingInterval, fetch));
  };

  const resetLoadingStates = () => {
    setLoading(false);
    setLoadingText("");
  };

  return (
    <div className={styles.gatacaQR__container}>
      <>
        {
          // @ts-ignore
          <gataca-qr
            callback-server={server}
            ref={qr}
            hide-modal-texts="true"
            hide-modal-box-shadow="true"
            modal-width="300"
            qr-size="300"
            logo-src={qrIcon}
            polling-frequency="3"
            qr-role="credential"
            v-2="true"
            credentials-not-validated-label={t("errors.errorDetectedTryAgain")}
            session-id={sessionId}
          />
        }
        {loadingAfterRead ? (
          <RetrievingInformation className={styles.loadingQR__issuance} />
        ) : null}
        {loading ? (
          <LoadingQR
            className={styles.loadingQR__issuance}
            text={loadingText}
          />
        ) : null}
      </>
    </div>
  );
};

export default IssuanceQR;
