import { Epic, ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import {
  createdCredential,
  createdCredentialFailed,
  createdProcess,
  getIsCredentialSentFailed,
  getIsCredentialSentSuccess,
} from "./credentialActions";
import dataService from "../../services/dataService";
import { CredentialActionTypes } from "./credentialTypes";
import { IRequestItem } from "../../interfaces/interfaces";
import { setSessionStorageData } from "../../services/sessionStorageService";

const mockProcess = "id_test";

export const createIssuanceSessionEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(CredentialActionTypes.CREATE_ISSUANCE_SESSION),
    switchMap((action) => {
      const UCStepId = action.payload.useCaseStepId;

      return dataService.createIssuanceSession(UCStepId).pipe(
        map((response: any) => {
          setSessionStorageData("ga__process", response?.response?.identifier);
          return createdProcess(response?.response?.identifier, "");
        }),
        catchError((error) => {
          return of(createdProcess(mockProcess, error));
        })
      );
    })
  );

export const createVerificationSessionEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(CredentialActionTypes.CREATE_VERIFICATION_SESSION),
    switchMap((action) => {
      const UCStepId = action.payload.useCaseStepId;

      return dataService.createVerificationSession(UCStepId).pipe(
        map((response: any) => {
          setSessionStorageData("ga__process", response?.response?.identifier);
          return createdProcess(response?.response?.identifier, "");
        }),
        catchError((error) => {
          return of(createdProcess(mockProcess, error));
        })
      );
    })
  );

export const getRequestEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(CredentialActionTypes.GET_SESSION),
    switchMap((action) => {
      const processID = action.payload.processId;
      const UCStepId = action.payload.useCaseStepId;

      return dataService.createRequest(processID, UCStepId).pipe(
        map((response: any) => {
          return createdCredential(response?.response as IRequestItem);
        }),
        catchError((error) => {
          return of(createdCredentialFailed());
        })
      );
    })
  );

export const getCredentialIsSentEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(CredentialActionTypes.GET_IS_CREDENTIAL_SENT),
    switchMap((action) => {
      const UCStepId = action.payload.useCaseStepId;
      const processId = action.payload.processId;

      return dataService.getCredIsSent(UCStepId, processId).pipe(
        map((response: any) => {
          return getIsCredentialSentSuccess(response?.response?.status);
        }),
        catchError((error) => of(getIsCredentialSentFailed()))
      );
    })
  );
