import React from "react";
import styles from "./multiSector.module.scss";
import cx from "classnames";

const MultiSector: React.FC = () => {
  return (
    <>
      <div className={`${styles?.multiSector} ${cx("gridWidth stepMaxWidth")}`}>
        <div>
          <p>Multisector</p>
        </div>
      </div>
    </>
  );
};

export default MultiSector;
