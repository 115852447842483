import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./stepsContent.module.scss";
import PurpleButton from "../../atoms/buttons/purple/PurpleButton";
import { StepsContentModel } from "../../../interfaces/interfaces";
import CredentialType from "../credentialType/CredentialType";

interface IStepsContentProps {
  data?: StepsContentModel | any;
  QRComponent?: any;
  buttonAction?: ((x?: any) => void) | any | Function;
  classNameQRContainer?: string;
  classNameRightSideContainer?: string;
  classNameBgContainer?: string;
  AlertComponent?: any;
  classNameMainContainer?: string;
}
const StepsContent: React.FC<IStepsContentProps> = (props) => {
  const {
    className,
    title,
    imageBg,
    imageBgStep,
    imageBgGatacaLogo,
    descriptionParagraphs,
    descriptionParagraphsQR,
    button,
    explanation,
    credentialComponent,
  } = props?.data;
  const {
    QRComponent,
    AlertComponent,
    buttonAction,
    classNameQRContainer,
    classNameRightSideContainer,
    classNameBgContainer,
    classNameMainContainer,
  } = props;
  const { t } = useTranslation();

  const scrollToTop = () => {
    let rootContainer = document ? document?.getElementById("root") : undefined;
    rootContainer?.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollToTop();

  const isBrowser = typeof window !== "undefined";
  const [dimensions, setDimensions] = useState(
    isBrowser ? window.innerWidth || Math.min(window.innerWidth) : 0
  );
  const handleResize = () => {
    setDimensions(
      isBrowser ? window.innerWidth || Math.min(window.innerWidth) : 0
    );
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const width = Math.min(dimensions);
  const bigResolution = width > 1512;
  const smallResolution = width < 1024;

  return (
    <div
      className={`${styles?.backgroundImageContainer} ${classNameBgContainer && classNameBgContainer}`}
      style={
        bigResolution
          ? {
              backgroundImage: `url(${imageBgGatacaLogo?.src})`,
            }
          : {
              backgroundImage: `url(${imageBg?.src})`,
            }
      }>
      <div
        className={`${styles?.stepsContent__mainContainer} ${cx("gridWidth stepMaxWidth")} ${classNameMainContainer && classNameMainContainer}`}
        style={
          bigResolution
            ? {
                backgroundImage: `url(${imageBgStep?.src})`,
              }
            : {
                backgroundImage: `none`,
              }
        }>
        <div
          className={`${styles?.stepsContent__container} ${className && className}`}>
          <div className={`${styles?.stepsContent__descriptionTexts}`}>
            <h2>{t(title)}</h2>

            <div className={`${styles?.paragraphs}`}>
              {Array.isArray(descriptionParagraphs) &&
                descriptionParagraphs?.map((el: string, index: number) => {
                  return (
                    <p
                      key={"paragraph" + index}
                      className={`${styles?.normalText} ${cx("neutral100")}`}
                      dangerouslySetInnerHTML={{
                        __html: t(descriptionParagraphs[index]),
                      }}></p>
                  );
                })}
            </div>

            {AlertComponent ? (
              <div className={styles.staticAlert}>
                {AlertComponent && AlertComponent()}
              </div>
            ) : null}

            {credentialComponent && (
              <>
                {explanation && (
                  <p className={`${cx("marginBottom12 bodyRegularLG")}`}>
                    {t(explanation)}
                  </p>
                )}
                <div className={`${styles?.credentialComponent__container}`}>
                  {credentialComponent?.map((item: any, index: any) => {
                    return (
                      <CredentialType
                        key={"credential__type" + index}
                        icon={item.icon}
                        category={t(item.category)}
                        issuer={t(item.issuer)}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {descriptionParagraphsQR && (
              <div
                className={`${styles?.paragraphs} ${styles?.descriptionParagraphsQR}`}>
                {Array.isArray(descriptionParagraphsQR) &&
                  descriptionParagraphsQR?.map((el: string, index: number) => {
                    return (
                      <p
                        key={"paragraph" + index}
                        className={`${styles?.normalText__noMargin} ${cx("neutral100")}`}
                        dangerouslySetInnerHTML={{
                          __html: t(descriptionParagraphsQR[index]),
                        }}></p>
                    );
                  })}
              </div>
            )}
            {button && (
              <div className={`${styles?.button} ${cx("marginBottom32")}`}>
                <PurpleButton
                  className={`${button?.className}}`}
                  label={t(button?.label)}
                  action={buttonAction}
                />
              </div>
            )}
          </div>
          <div
            className={`${styles?.stepsContent__rightSide} ${styles?.bgImageContainerColumn} ${classNameRightSideContainer && classNameRightSideContainer}`}
            style={
              bigResolution
                ? {
                    backgroundImage: `none`,
                  }
                : smallResolution
                  ? {
                      backgroundImage: `url(${imageBg?.src})`,
                    }
                  : { backgroundImage: `none` }
            }>
            {QRComponent ? (
              <div
                className={`${styles?.stepsContent__QRContainer} ${classNameQRContainer && classNameQRContainer}`}>
                <div
                  className={`${styles?.stepsContent__QRContainer__limitations}`}>
                  {QRComponent && QRComponent()}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsContent;
