import React, { useEffect, useState } from "react";
import styles from "./header.module.scss";
import { gaImages } from "../../../data/images";
import GaImage from "../../atoms/Images/gaImage";
import StepperProgressBar from "../navigation/StepperProgressBar/StepperProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { navigationSelectors } from "../../../store/navigation";
import { useCasesSelectors } from "../../../store/useCases";
import { navigateToAction } from "../../../store/navigation/navigationActions";
import LogOutIcon from "../../../assets/iconsLibrary/simple/logout/logoutIcon";

const Header: React.FC = () => {
  const dispatch = useDispatch();

  // States
  const selectedUseCaseSteps = useSelector(
    useCasesSelectors.getSelectedUseCaseSteps
  );
  const activeRoute = useSelector(navigationSelectors.activeRoute);
  const activeUseCaseStp = useSelector(useCasesSelectors.activeUseCaseStepId);

  const [selectedUseCaseStepId, setSelectedUseCaseStepId] = useState(
    selectedUseCaseSteps?.findIndex((el) => el?.id === activeUseCaseStp)
  );
  // Variables

  const showStepper =
    selectedUseCaseSteps &&
    activeRoute !== "/demo" &&
    activeRoute !== "/demo/home" &&
    !activeRoute?.includes("download-wallet") &&
    !activeRoute?.includes("finish");

  // Effects
  useEffect(() => {
    setSelectedUseCaseStepId(
      selectedUseCaseSteps?.findIndex((el) => el?.id === activeUseCaseStp)
    );
  }, [selectedUseCaseSteps, activeUseCaseStp]);

  useEffect(() => {}, [selectedUseCaseStepId]);

  // Functions
  const goToHome = () => {
    dispatch(navigateToAction("/demo"));
  };

  return (
    <>
      <header className={styles?.header}>
        <a className={styles?.header__logo} href={"/demo"}>
          <GaImage image={gaImages.logoGataca} />
        </a>
        {showStepper && selectedUseCaseSteps?.length ? (
          <StepperProgressBar
            activeStep={
              selectedUseCaseStepId && selectedUseCaseStepId > 0
                ? selectedUseCaseStepId
                : 0
            }
            steps={selectedUseCaseSteps}
          />
        ) : null}
        {showStepper && (
          <div onClick={() => goToHome()} className={styles.header__exitButton}>
            <LogOutIcon size={24} color="#ffffff" />
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
