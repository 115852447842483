import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Home from "../../../features/Home/views/Home";
import MultiSector from "../../../features/UseCases/MultiSector/views/MultiSector";
import DownloadWallet from "../../../features/DownloadWallet/views/DownloadWallet";
import IssuedCredential from "../../../features/IssuanceComplete/views/IssuanceComplete";
import IdentityVerification from "../../../features/IdentityVerification/views/IdentityVerification";
import ReceiveCredentials from "../../../features/ReceiveCredentials/views/ReceiveCredentials";
import { history } from "../../../services/history";
import CustomRouter from "../navigation/CustomRouter/CustomRouter";
import FlowFinish from "../../../features/FlowFinish/FlowFinish";
import { useDispatch, useSelector } from "react-redux";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../../store/navigation/navigationActions";
import { navigationSelectors } from "../../../store/navigation";
import { useCasesSelectors } from "../../../store/useCases";
import GetAccess from "../../../features/GetAccess/views/GetAccess";
import RequestCredential from "../../../features/RequestCredential/views/RequestCredential";
import { getSessionStorageData } from "../../../services/sessionStorageService";
import { setSelectedUseCase } from "../../../store/useCases/useCasesActions";
import IssuanceComplete from "../../../features/IssuanceComplete/views/IssuanceComplete";
import { credentialSelectors } from "../../../store/credentials";
import { createdProcess } from "../../../store/credentials/credentialActions";
import AlertNotification from "../Alert/alertNotification/AlertNotification";
import { hideAlert } from "../../../store/alerts/alertActions";

const Layout: React.FC = () => {
  const dispatch = useDispatch();
  const activeRouteInSessionStorage = getSessionStorageData("ga_route");
  const selectedUCIDInSessionStorage = getSessionStorageData("ga__UC");
  const processIdInSessionStorage = getSessionStorageData("ga__process");

  // Selectors
  const activeRoute = useSelector(navigationSelectors.activeRoute);
  const selectedUseCase = useSelector(useCasesSelectors.selectedUseCaseId);
  const currentQRProcessId = useSelector(credentialSelectors.getProcessId);

  const currentRouteNeedSelectedUseCase =
    activeRoute !== "/demo/" &&
    activeRoute !== "/demo/home" &&
    !activeRoute?.includes("download-wallet") &&
    !activeRoute?.includes("finish");

  // Effects
  useEffect(() => {
    if (!selectedUCIDInSessionStorage && !selectedUseCase) {
      goToHome();
    } else if (activeRouteInSessionStorage) {
      dispatch(setSelectedRoute(activeRouteInSessionStorage));
    }
  }, [activeRoute]);

  useEffect(() => {
    manageUCIDState();
    manageProccessIdState();
    manageAlertsDisplay();
  }, [activeRoute]);

  // Functions
  const goToHome = () => {
    dispatch(navigateToAction("/demo"));
  };

  const manageUCIDState = () => {
    if (
      !selectedUseCase &&
      !!currentRouteNeedSelectedUseCase &&
      selectedUCIDInSessionStorage
    ) {
      dispatch(setSelectedUseCase(selectedUCIDInSessionStorage));
    }
  };

  const manageProccessIdState = () => {
    if (
      !currentQRProcessId &&
      !!currentRouteNeedSelectedUseCase &&
      selectedUCIDInSessionStorage &&
      processIdInSessionStorage
    ) {
      dispatch(createdProcess(processIdInSessionStorage, ""));
    }
  };

  const manageAlertsDisplay = () => {
    if (!currentRouteNeedSelectedUseCase) {
      dispatch(hideAlert());
    }
  };

  return (
    <>
      <Header />
      <main>
        <CustomRouter history={history}>
          <Routes>
            <Route path="*" element={<Home />}></Route>
            <Route path="/demo/*" element={<Demo />}></Route>
            <Route path="/demo" element={<Home />}></Route>
          </Routes>
        </CustomRouter>
      </main>
      {(activeRoute === "/" || activeRoute === "/demo") && <Footer />}
      <AlertNotification />
    </>
  );
};

function Demo() {
  return (
    <>
      <Routes>
        <Route path={`/:UCPath/download-wallet`} element={<DownloadWallet />} />
        <Route path="/:UCPath/multisector" element={<MultiSector />} />

        <Route
          path="/:UCPath/receive-credentials"
          element={<ReceiveCredentials />}
        />
        <Route
          path="/:UCPath/identity-verification"
          element={<IdentityVerification />}
        />
        <Route
          path="/:UCPath/issued-credential"
          element={<IssuedCredential />}
        />
        {/* Third Step of use cases flows */}
        <Route path="/:UCPath/get-access" element={<GetAccess />} />
        <Route
          path="/:UCPath/request-credential"
          element={<RequestCredential />}
        />
        {/* Fourth Step of use cases flows */}
        <Route
          path="/:UCPath/issuance-complete"
          element={<IssuanceComplete />}
        />
        <Route path="/:UCPath/finish" element={<FlowFinish />} />
      </Routes>
    </>
  );
}

export default Layout;
