import * as React from "react";
import styles from "./verifiableId.module.scss";
import cx from "classnames";
import { ImageModel } from "../../../interfaces/interfaces";
import GaImage from "../../atoms/Images/gaImage";
import { useTranslation } from "react-i18next";
import AnimatedLoader from "../../atoms/loaders/animatedLoader/AnimatedLoader";
import { LoaderSizes } from "../../../data/display";

export type IVerifiableIdProps = {
  icon: ImageModel;
  category: string;
  issuer: string;
  idNumber: string;
  iconProcess?: ImageModel;
  className?: string;
  loading?: boolean;
};

const VerifiableId: React.FC<IVerifiableIdProps> = (props) => {
  const { icon, category, issuer, className, idNumber, iconProcess, loading } =
    props;
  const { t } = useTranslation();

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.header}`}>
          <div>
            <GaImage image={icon} />
            <div>
              <p className={`${cx("buttonMD neutral1000")}`}>{category}</p>
              <p className={`${cx("bodyRegularSM neutral700")}`}>{issuer}</p>
            </div>
          </div>
          {!loading && iconProcess ? (
            <GaImage image={iconProcess} />
          ) : (
            <AnimatedLoader size={LoaderSizes.SM} />
          )}
        </div>
        <p className={`${styles.mainData} ${cx("bodyBoldXL")}`}>{idNumber}</p>
        <div className={`${styles.datesContainer}`}>
          <div>
            <p className={`${cx("buttonSM marginBottom2 neutral1000")}`}>
              {t("useCases.issuanceDateText")}
            </p>
            <p className={`${cx("bodyRegularSM neutral700")}`}>
              {t("useCases.issuanceDate")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifiableId;
