import * as React from "react";
import cx from "classnames";
import styles from "./secondSection.module.scss";
import { t } from "i18next";
import {
  UseCaseCategories,
  useCases2,
  useCasesCategories,
} from "../../../../data/useCasesData";
import UseCaseCard from "../../components/useCaseCard/useCaseCard";
import { useEffect, useState } from "react";

export type ISectionProps = {};

const SecondSection: React.FC<ISectionProps> = (props) => {
  const useCaseOfSelectedCategory = (categoryId: string) =>
    useCases2?.filter((useCase) =>
      useCase?.categories?.includes(
        UseCaseCategories[categoryId as keyof typeof UseCaseCategories]
      )
    );

  // Variables & States
  const [selectedCategoryId, setSelectedCategoryId] = useState("all");

  const catUseCases = useCaseOfSelectedCategory(selectedCategoryId);

  const [selectedCategoryUseCases, setSelectedCategoryUseCases] =
    useState(catUseCases);

  let categoriesContainer: HTMLElement | null | undefined;

  // Effects
  useEffect(() => {
    categoriesContainer = document
      ? document?.getElementById("categoriesContainer")
      : undefined;
  });

  useEffect(() => {
    setSelectedCategoryUseCases(catUseCases);
  }, [selectedCategoryId]);

  // Functions
  const scrollIntoView = (el: any) => {
    setTimeout(() => {
      return categoriesContainer
        ? categoriesContainer.scroll({
            behavior: "smooth",
            left: el.offsetLeft,
          })
        : null;
    }, 0);
  };

  const categoriesWithUseCases = useCasesCategories?.filter((category) => {
    return useCases2.some(function (useCase) {
      return useCase?.categories?.includes(
        UseCaseCategories[category?.id as keyof typeof UseCaseCategories]
      );
    });
  });

  return (
    <section className={`${styles.secondSection}`}>
      <p className={` ${cx("heading1 testAlignCenter")}`}>
        {t("home.secondSection.title")}
      </p>
      <div
        id="categoriesContainer"
        className={`${cx("heading3")} ${styles.categoriesContainer}`}>
        {categoriesWithUseCases?.map((el, index) => {
          return selectedCategoryId === el?.id ? (
            <div
              id={"category__" + index}
              className={`${styles.categorySelectedOption} ${cx("bodyBoldLG")}`}
              key={"categoryButton__" + index}>
              <p>{t(el?.text)}</p>
            </div>
          ) : (
            <div
              id={"category__" + index}
              onClick={() => {
                const element =
                  document && document?.getElementById("category__" + index);
                return (
                  setSelectedCategoryId(el?.id),
                  element && scrollIntoView(element)
                );
              }}
              key={"categoryButton__" + index}
              className={`${styles.categoryOption} ${cx("buttonMD")}`}>
              <p className={`${cx("buttonMD")}`}>{t(el?.text)}</p>
            </div>
          );
        })}
      </div>
      <div className={styles.secondSection__useCasesContainer}>
        {selectedCategoryUseCases?.map((useCase, index) => {
          return (
            <div key={"useCaseCard__" + index}>
              <UseCaseCard
                id={useCase.id}
                data={useCase?.homeCard}
                path={useCase?.path || "use-case"}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SecondSection;
