import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { NavigationActionsTypes } from "./navigationTypes";
import { history } from "../../services/history";
import { setSelectedRoute } from "./navigationActions";

export const navigateEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(NavigationActionsTypes.NAVIGATE),
    switchMap((action: any): any => {
      history.push(action.path);
      return of(setSelectedRoute(action.path));
    })
  );
};
