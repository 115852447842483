import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import commonEn from "./locales/common/en.json";
import commonEs from "./locales/common/es.json";

const resources = {
  es: { common: commonEs },
  en: { common: commonEn },
};

const options = {
  order: ["navigator"],
  lookupQuerystring: "lng",
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    ns: ["common"],
    defaultNS: "common",
    fallbackLng: "en",
    supportedLngs: ["es", "en"],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

// Check the localstorage saved language
const storedLang = localStorage.getItem("i18nextLng");
const initialLang =
  storedLang?.length && storedLang?.length > 0 ? storedLang : "en";
const supportedLngs = ["es", "en"];
const applicableLabels =
  initialLang && initialLang === "es"
    ? require("./locales/common/es.json")
    : require("./locales/common/en.json");

export default i18n;
export { initialLang, supportedLngs, applicableLabels };
