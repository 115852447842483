import { throwError } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";

const MOCKED_HOST = process.env.REACT_APP_DEMOLAND_HOST;

function handleNetworkErrors(error: any) {
  let errorToReturn: any = undefined;
  switch (error.status) {
    // Expired Token
    case 401:
      sessionStorage.clear();
      break;
    // Invalid token
    case 403:
      errorToReturn = throwError(error);
      break;
    case 422:
      errorToReturn = throwError(error);
      break;
    case 429:
      errorToReturn = throwError(error);
      break;
    case 409:
      errorToReturn = throwError(error);
      break;
    case 500:
      errorToReturn = throwError(error);
      break;
    default:
      return error;
  }
  return !!errorToReturn ? errorToReturn : null;
}

export interface IGaasReq {
  url: string;
  method: string;
  headers?: any;
  body?: any;
  vouch?: any;
  // TODO: remove when login API is ready
  origin?: string;
  referer?: string;
  withCredentials?: boolean;
}

export interface ProcessEnv {
  [key: string]: string | undefined;
}

export const gaasReq = (payload: IGaasReq) => {
  const { url } = payload;

  return ajax({
    url: MOCKED_HOST + url,
    method: payload.method,
    headers: {
      ...payload.headers,
    },
    body: payload.body,
    withCredentials: true,
    crossDomain: true,
  }).pipe(
    map((response) => {
      return response;
    }),
    catchError((err) => {
      return handleNetworkErrors(err);
    })
  );
};
