import * as React from "react";
import styles from "./credentialType.module.scss";
import cx from "classnames";
import { ImageModel } from "../../../interfaces/interfaces";
import GaImage from "../../atoms/Images/gaImage";

export type ICredentialTypeProps = {
  icon: ImageModel;
  category: string;
  issuer: string;
  className?: string;
};

const CredentialType: React.FC<ICredentialTypeProps> = (props) => {
  const { icon, category, issuer, className } = props;
  return (
    <>
      <div className={`${styles?.card} ${className && className}`}>
        <div className={`${styles?.elementsContainer}`}>
          <GaImage image={icon} />
          <div>
            <p className={`${cx("buttonMD")}`}>{category}</p>
            <p className={`${cx("bodyRegularSM")}`}>{issuer}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CredentialType;
