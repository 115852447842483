import {
  IHideAlertAction,
  IShowAlertAction,
  AlertActionTypes,
  IAlertItem,
} from "./alertTypes";

export const showAlert = (alert: IAlertItem): IShowAlertAction => {
  const { kind, title, subtitle, trailingIcon, closeButton, status, duration } =
    alert;
  return {
    type: AlertActionTypes.SHOW_ALERT,
    payload: {
      alert: {
        title: title,
        kind: kind,
        subtitle: subtitle,
        trailingIcon: trailingIcon,
        closeButton: closeButton,
        status: status,
        duration: duration,
      },
    },
  };
};

export const hideAlert = (): IHideAlertAction => {
  return {
    type: AlertActionTypes.HIDE_ALERT,
  };
};
