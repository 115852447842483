import { combineEpics } from "redux-observable";
import { navigationEpics } from "./navigation";
import { credentialEpics } from "./credentials";

const rootEpic = combineEpics(
  navigationEpics.navigateEpic,
  credentialEpics.createIssuanceSessionEpic,
  credentialEpics.createVerificationSessionEpic,
  credentialEpics.getRequestEpic,
  credentialEpics.getCredentialIsSentEpic
);

export default rootEpic;
