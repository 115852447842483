import React from "react";

type CloseIconProps = {
  size?: number;
  color?: string;
  className?: string;
};

const CloseIcon: React.FC<CloseIconProps> = React.memo((props) => {
  const { size, color, className } = props;

  return (
    <svg
      className={className && className}
      width={size || "24"}
      height={size || "24"}
      viewBox="-5 -5 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
        fill={color || "currentColor" || "#1E1E20"}
      />
    </svg>
  );
});

export default CloseIcon;
