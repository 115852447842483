import {
  CredentialActionTypes,
  IClearStateAction,
  ICreateIssuanceSessionAction,
  ICreateVerificationSessionAction,
  ICreatedCredentialAction,
  ICreatedSessionAction,
  IGetCredentialAction,
  IGetIfCredHasIsSentAction,
  IGetIfCredHasIsSentSuccessAction,
  IStartIsCredentialSentPollingAction,
  IStopIsCredentialSentPollingAction,
} from "./credentialTypes";
import {
  IRequestItem,
  RESULT_ISSUANCE_STATUS,
} from "../../interfaces/interfaces";

export function createIssuanceSession(
  useCaseStepId: string
): ICreateIssuanceSessionAction {
  // console.debug("CREATE_ISSUANCE_SESSION ACTION group", group);
  return {
    type: CredentialActionTypes.CREATE_ISSUANCE_SESSION,
    payload: {
      useCaseStepId: useCaseStepId,
    },
  };
}

export function createVerificationSession(
  useCaseStepId: string
): ICreateVerificationSessionAction {
  // console.debug("CREATE_VERIFICATION_SESSION ACTION group", group);
  return {
    type: CredentialActionTypes.CREATE_VERIFICATION_SESSION,
    payload: {
      useCaseStepId: useCaseStepId,
    },
  };
}

export function createdProcess(
  processId: string,
  error: any
): ICreatedSessionAction {
  // console.debug("CREATED_SESSION ACTION", error);
  return {
    type: CredentialActionTypes.CREATED_SESSION,
    payload: {
      processId,
      error,
    },
  };
}

export function createdCredential(
  issuanceRequest: IRequestItem
): ICreatedCredentialAction {
  // console.debug("CREATED_CREDENTIAL ACTION");
  return {
    type: CredentialActionTypes.CREATED_CREDENTIAL,
    payload: {
      credentials: issuanceRequest,
    },
  };
}

export function createdCredentialFailed() {
  // console.debug("CREATED_CREDENTIAL ACTION");
  return {
    type: CredentialActionTypes.CREATED_CREDENTIAL_FAILED,
  };
}

export function getCredential(
  processId: string,
  useCaseStepId: string
): IGetCredentialAction {
  // console.debug("GET_SESSION ACTION");
  return {
    type: CredentialActionTypes.GET_SESSION,
    payload: {
      processId: processId,
      useCaseStepId: useCaseStepId,
    },
  };
}

export function getIsCredentialSent(
  useCaseStepId: string,
  processId: string
): IGetIfCredHasIsSentAction {
  // console.debug("GET IF CREDENTIAL IS SENT ACTION");
  return {
    type: CredentialActionTypes.GET_IS_CREDENTIAL_SENT,
    payload: { useCaseStepId: useCaseStepId, processId: processId },
  };
}

export function getIsCredentialSentSuccess(
  result: RESULT_ISSUANCE_STATUS | undefined
): IGetIfCredHasIsSentSuccessAction {
  // console.debug("GET IF CREDENTIAL IS SENT SUCCESS ACTION");
  return {
    type: CredentialActionTypes.GET_IS_CREDENTIAL_SENT_SUCCESS,
    payload: {
      result,
    },
  };
}

export function getIsCredentialSentFailed() {
  // console.debug("CREATED_CREDENTIAL ACTION");
  return {
    type: CredentialActionTypes.GET_IS_CREDENTIAL_SENT_FAILED,
  };
}

export function startIsCredentialSentPolling(
  interval: number,
  fetch: any
): IStartIsCredentialSentPollingAction {
  // console.debug("start polling action");
  return {
    type: CredentialActionTypes.START_IS_CREDENTIAL_SENT_POLLING,
    payload: {
      interval,
      fetch,
    },
  };
}

export function stopIsCredentialSentPolling(): IStopIsCredentialSentPollingAction {
  // console.debug("STOP CRED SENT POLLING ACTION");
  return {
    type: CredentialActionTypes.STOP_IS_CREDENTIAL_SENT_POLLING,
  };
}

export function clearCredAndProcessState(): IClearStateAction {
  // console.debug("CLEAR CREDS AND PROCESS ACTION");
  return {
    type: CredentialActionTypes.CLEAR_STATE,
  };
}
