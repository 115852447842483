import * as React from "react";
import cx from "classnames";
import { ButtonModel } from "../../../../interfaces/interfaces";
import styles from "./backButton.module.scss";

const BackButton: React.FC<ButtonModel> = (props) => {
  const { size, label, icon, IconComponent, className, disabled, action } =
    props;

  const sizeFontClass = {
    LG: "bodyRegularLG",
    MD: "bodyRegularMD",
    SM: "bodyRegularSM",
  };
  return (
    <button
      className={`${styles.backButton} ${className && className}`}
      onClick={action}
      disabled={disabled}
    >
      {icon && <img src={icon} alt={""} />}
      {IconComponent && IconComponent}
      <span className={cx(sizeFontClass[size || "SM"])}>{label}</span>
    </button>
  );
};

export default BackButton;
