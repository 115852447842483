import React, { useEffect } from "react";
import cx from "classnames";
import styles from "./downloadAppContent.module.scss";
import { ButtonModel, ImageModel } from "../../../interfaces/interfaces";
import { Link } from "react-router-dom";
import { marketAndroidURI, marketIosURI } from "../../../data/globalData";
import { gaImages } from "../../../data/images";
import GaImage from "../../../components/atoms/Images/gaImage";
import PurpleButton from "../../../components/atoms/buttons/purple/PurpleButton";
import { t } from "i18next";

type downloadAppContentProps = {
  className?: string;
  title: string;
  descriptionParagraph: string;
  buttonSkip?: ButtonModel;
  classNameQR?: string;
  QRimage: ImageModel;
  imageRightSide: ImageModel;
  helpLinkUrl: string;
  helpLinklabel: string;
};

const DownloadAppContent: React.FC<downloadAppContentProps> = (props) => {
  const {
    className,
    title,
    descriptionParagraph,
    buttonSkip,
    classNameQR,
    QRimage,
    imageRightSide,
    helpLinkUrl,
    helpLinklabel,
  } = props;

  // Effects
  useEffect(() => {
    scrollToTop();
  }, []);

  // Functions
  const scrollToTop = () => {
    let rootContainer = document ? document?.getElementById("root") : undefined;
    rootContainer?.scrollTo({ top: 0 });
  };

  return (
    <div
      className={`${styles?.downloadAppContent__container} ${className && className}`}>
      <div className={styles?.downloadAppContent__descriptionTexts}>
        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        <div className={`${styles?.paragraphs}`}>
          <p
            key={"paragraph"}
            className={`${cx(styles.paragraph, "neutral100")}`}>
            {descriptionParagraph}
          </p>
        </div>
        <div className={styles?.downloadAppContent__buttons}>
          <Link to={marketIosURI} target="_blank">
            <GaImage image={gaImages.brandAppleButton} />
          </Link>
          <Link to={marketAndroidURI} target="_blank" className={`${cx("")}`}>
            <GaImage image={gaImages.brandGoogleButton} />
          </Link>
        </div>
        <div>
          <PurpleButton
            className={`${buttonSkip?.className}`}
            label={buttonSkip?.label}
            action={buttonSkip?.action}
          />
        </div>
      </div>
      <div className={styles?.downloadAppContent__rightSide}>
        <div
          className={`${styles?.downloadAppContent__QRContainer} ${classNameQR}`}>
          <GaImage image={QRimage} />
          <Link to={helpLinkUrl} target="_blank">
            {helpLinklabel}
          </Link>
        </div>
        <div className={styles?.downloadAppContent__imageContainer}>
          <div>
            <GaImage image={imageRightSide} />
          </div>
          <div className={styles.downloadAppContent__logoContainer}></div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppContent;
