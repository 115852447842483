import {
  IScafoldingState,
  NavigationActions,
  NavigationActionsTypes,
} from "./navigationTypes";

// NAVIGATION
const initialNavigationsState: IScafoldingState = {
  activeRoute: "/demo",
};

export function navigationReducer(
  state = initialNavigationsState,
  action: NavigationActions
): IScafoldingState {
  switch (action.type) {
    case NavigationActionsTypes.SET_ACTIVE_ROUTE:
      return {
        ...state,
        activeRoute: action.payload?.route,
      };
    default:
      return state;
  }
}
