import {
  ISetActiveUseCaseStep,
  ISetSelectedUseCase,
  UseCasesActionsTypes,
} from "./useCasesTypes";

export const setSelectedUseCase = (
  id: string | undefined
): ISetSelectedUseCase => {
  return {
    type: UseCasesActionsTypes.SET_SELECTED_USE_CASE,
    payload: {
      id,
    },
  };
};

export const setActiveUseCaseStep = (
  id: string | undefined
): ISetActiveUseCaseStep => {
  return {
    type: UseCasesActionsTypes.SET_ACTIVE_USE_CASE_STEP,
    payload: {
      id,
    },
  };
};
