export const contentSP = `
    connect-src 'self' 
    http://localhost:3001
    http://demoland-api
    http://test.dev.gataca.io/demoland-api/admin/v1/usecases
    https://test.dev.gataca.io
    https://test.dev.gataca.io/admin/v1/usecases
    http://test.dev.gataca.io/admin/v1/usecases/obtainverifiableid/issuances
    data: ;
    default-src 'self' 
    data: ;
    img-src 'self' 
    data: ;
    style-src 'self' 
    https://fonts.googleapis.com/ 
    https://fonts.gstatic.com/ 
    'sha256-0hAheEzaMe6uXIKV4EehS9pu1am1lj/KnnzrOYqckXk='
    'sha256-fWMZWhOCcgTibzAvyPuhBp48OhJBLjI2e1MBzTauoI0='
    'sha256-2j+NsrE/qRlmhkADxLdqK0AALIC4Gcc77SVRgwXmYCc=';
    font-src 'self' 
    https://fonts.googleapis.com/ 
    https://fonts.gstatic.com/ 
`;
