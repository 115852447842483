import FirstStep from "./processSteps/FirstStep/FirstStep";
import SecondStep from "./processSteps/SecondStep/SecondStep";

// Steps
export const viewSteps = [
  {
    id: "1",
    content: (manageSubStepChange: () => void) => (
      <FirstStep manageSubStepChange={manageSubStepChange} />
    ),
  },
  // This step is not removed just in case it comes in handy again in the future.
  // Right now it is being emulated in step 1
  // {
  //   id: "2",
  //   content: (manageSubStepChange: () => void) => (
  //     <SecondStep manageSubStepChange={manageSubStepChange} />
  //   ),
  // },
];
