import { IGaasReq, gaasReq } from "./networkService";

const DEMOLAND_HOST =  process.env.REACT_APP_DEMOLAND_HOST;

export default {
  // CREATE SESSIONS
  createIssuanceSession(UCStepId: string) {
    const call: IGaasReq = {
      method: "post",
      url: `/admin/v1/usecases/${UCStepId}/issuances`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  createVerificationSession(UCStepId: string) {
    const call: IGaasReq = {
      method: "post",
      url: `/admin/v1/usecases/${UCStepId}/sessions`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
    };
    return gaasReq(call);
  },
  // CREATE REQUEST
  createRequest(processId: string, UCStepId: string) {
    const call: IGaasReq = {
      method: "get",
      url: `/admin/v1/usecases/${UCStepId}/issuances/${processId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  // QR CHECK STATUS
  async issuanceProcessCheckStatus(sessionId: string, UCStepId: string) {
    let response = await fetch(
      DEMOLAND_HOST + `/admin/v1/usecases/${UCStepId}/issuances/${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  },
  async verifierProcessCheckStatus(sessionId: string, UCStepId: string) {
    let response = await fetch(
      DEMOLAND_HOST + `/admin/v1/usecases/${UCStepId}/sessions/${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  },
  // QR CREATE SESSION
  async issuanceProcessCreateSession(UCStepId: string) {
    let response = await fetch(
      DEMOLAND_HOST + `/admin/v1/usecases/${UCStepId}/issuances`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  },
  async verifierProcessCreateSession(UCStepId: string) {
    let response = await fetch(
      DEMOLAND_HOST + `/admin/v1/usecases/${UCStepId}/sessions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  },

  //

  async issueCredential(UCStepId: string, issuanceId: string) {
    const response = await fetch(
      DEMOLAND_HOST + `/admin/v1/usecases/${UCStepId}/issuances/${issuanceId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  },

  // GET IF CREDENTIAL HAS BEEN SENT TO WALLET
  getCredIsSent(UCStepId: string, issuanceId: string) {
    const call: IGaasReq = {
      method: "get",
      url: `/admin/v1/usecases/${UCStepId}/issuances/${issuanceId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
};
