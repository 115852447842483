import React, { useEffect, useState } from "react";
import styles from "./getAccess.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCasesSelectors } from "../../../store/useCases";
import { setActiveUseCaseStep } from "../../../store/useCases/useCasesActions";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../../store/navigation/navigationActions";
import { viewSteps } from "../data";
import { setSessionStorageData } from "../../../services/sessionStorageService";
import BackButton from "../../../components/atoms/buttons/back/BackButton";
import ChevronLeftSVG from "../../../assets/icons/ChevronLeftSVG";

const GetAccess: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Selectors
  const selectedUCSteps = useSelector(
    useCasesSelectors.getSelectedUseCaseSteps
  );
  const selectedUCPath = useSelector(useCasesSelectors.getSelectedUseCasePath);

  // States
  const [activeSubStepIndex, setActiveSubStepIndex] = useState(0);
  const activeStepC = viewSteps[activeSubStepIndex]?.content;

  // Effects
  useEffect(() => {
    selectedUCSteps?.length &&
      selectedUCSteps[2] &&
      (dispatch(
        setSelectedRoute(`/demo/${selectedUCPath}/${selectedUCSteps[2]?.id}`)
      ),
      dispatch(setActiveUseCaseStep(selectedUCSteps[2]?.id)));
  }, [selectedUCSteps]);

  // Functions
  const goToFinish = () => {
    dispatch(navigateToAction(`/demo/${selectedUCPath}/finish`));
  };

  const goToPreviousStep = () => {
    setSessionStorageData("ga__subStp", "0");
    selectedUCSteps?.length &&
      selectedUCSteps[0] &&
      dispatch(
        navigateToAction(`/demo/${selectedUCPath}/${selectedUCSteps[1]?.id}`)
      );
  };

  const manageSubStepChange = () => {
    const stepsQty = viewSteps?.length;
    activeSubStepIndex === stepsQty - 1
      ? goToFinish()
      : setActiveSubStepIndex(activeSubStepIndex + 1);
  };

  return (
    <>
      <div className={`${styles?.getAccess}`}>
        <div className={cx("gridWidth stepMaxWidth")}>
          <div className={cx("useCaseBackContainer")}>
            <BackButton
              label={t("backButton")}
              action={() => goToPreviousStep()}
              IconComponent={<ChevronLeftSVG />}
            />
          </div>
        </div>
        {activeStepC && activeStepC(manageSubStepChange)}
      </div>
    </>
  );
};

export default GetAccess;
