import { ProcessTypes, UseCaseCategories } from "../data/useCasesData";
import { ButtonSizes } from "../data/display";
import { FunctionComponent, SVGProps } from "react";
import { UseCasesFlowSteps } from "../data/flowsData";

export interface ImageModel {
  src: string;
  alt: string;
}

export interface BgImageModel {
  src: string;
}

export interface LinkModel {
  id: string;
  label: string;
  route?: string;
  categories?: LinkModel[];
  subRoutes?: LinkModel[];
  mainRoute?: LinkModel[];
  action?: (...args: any[]) => {};
}

export interface ButtonModel {
  label?: string;
  icon?: string;
  IconComponent?: any;
  image?: ImageModel;
  disabledIcon?: ImageModel;
  disabledTooltip?: boolean;
  hideIconTooltip?: boolean;
  disabledTooltipLabel?: string;
  svgIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  action?: ((x?: any) => void) | any | Function;
  outlined?: boolean;
  size?: ButtonSizes;
  className?: any;
  url?: string;
  fileName?: string;
}

export interface UseCaseModel {
  id: string;
  categories: UseCaseCategories[];
  homeCard: UseCaseHomeCardModel;
  steps?: UseCasesFlowSteps[];
  type: ProcessTypes;
  path: string;
  customQRIcon: string;
  firstStep: {
    id: string;
    receiveCredentials: StepsContentModel;
    requestCredential?: StepsContentModel;
    identityVerification: StepsContentModel;
  };
  secondStep: {
    id: string;
    receiveCredentials: StepsContentModel;
    requestCredential?: StepsContentModel;
    identityVerification: StepsContentModel;
  };
  thirdStep?: {
    id: string;
    receiveCredentials: StepsContentModel;
    identityVerification?: StepsContentModel;
  };
  issuanceComplete?: StepsContentModel;
  getAccess?: StepsContentModel;
}

export interface UseCaseModel2 {
  id: string;
  categories: UseCaseCategories[];
  homeCard: UseCaseHomeCardModel;
  steps?: UseCasesFlowSteps[];
  type: ProcessTypes;
  path: string;
  customQRIcon: string;
  receiveCredentials: {
    id: string;
    firstStep: StepsContentModel;
    secondStep: StepsContentModel;
    thirdStep: StepsContentModel;
  };
  identityVerification: {
    id: string;
    firstStep: StepsContentModel;
    secondStep: StepsContentModel;
    thirdStep?: StepsContentModel;
  };
  requestCredential: {
    id: string;
    firstStep: StepsContentModel;
    secondStep: StepsContentModel;
  };
  issuanceComplete?: StepsContentModel;
  getAccess?: StepsContentModel;
}
export interface UseCaseHomeCardModel {
  title: string;
  by: string;
  holder: string;
  verifier: string;
  image: ImageModel;
}

export interface FlowStepModel {
  id: UseCasesFlowSteps;
  text: string;
}

export type UserModel = {
  firstName: string;
  lastName: string;
  picture: string;
  studentId: string;
};

export enum RESULT_SESSION_STATUS {
  ONGOING = 0,
  SUCCESS = 1,
  FAILED = 2,
  READED = 5,
}

export enum RESULT_ISSUANCE_STATUS {
  NOT_STARTED = -1,
  ONGOING = 0,
  SUCCESS = 1, // CREDS SENT => THEN 3
  FAILED = 2, // PENDING
  VALIDATED = 3, // READY TO DOWNLOAD
  ISSUED = 4, // DOWNLOADED
  READED = 5, // READED
}

// Credential models
export interface ICredentialRequestItem {
  type: string;
  purpose: string;
  mandatory: boolean;
  trustLevel: number;
}

export interface IRequestItem {
  id: string;
  status: string;
  group: string;
  createdAt: string;
  updatedAt: string;
  validator?: any;
  data?: any[];
  requester?: string;
}

export interface ICredentialItem {
  id?: string;
  type: string[];
  issuer?: string;
  credentialSubject: Map<string, Object>;
}

export enum ApiStatus {
  LOADING = "loading",
  LOADED = "loaded",
  FAILED = "failed",
}

export interface StepsContentModel {
  className?: string;
  title: string;
  imageBg: BgImageModel;
  imageBgStep: BgImageModel;
  imageBgGatacaLogo: BgImageModel;
  descriptionParagraphs: string | string[];
  descriptionParagraphsQR?: string | string[];
  button?: ButtonModel;
  explanation?: string;
  credentialComponent?: {
    icon: ImageModel;
    category: string;
    issuer: string;
  }[];
}
