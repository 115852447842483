import * as React from "react";
import styles from "./transparentButton.module.scss";
import cx from "classnames";
import { ButtonModel } from "../../../../interfaces/interfaces";

const WhiteButton: React.FC<ButtonModel> = (props) => {
  const { label, size, outlined, className, action } = props;

  const sizeFontClass = {
    LG: "buttonLG",
    MD: "buttonMD",
    SM: "buttonSM",
  };

  return (
    <button
      className={`${outlined ? styles?.outlinedButton : styles?.button} ${cx(sizeFontClass[size || "LG"])} ${className && className}`}
      onClick={action}>
      {label}
    </button>
  );
};

export default WhiteButton;
