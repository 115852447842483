import React, { useState } from "react";
import StepsContent from "../../../../components/elements/stepsContent/stepsContent";
import { useSelector } from "react-redux";
import { useCasesSelectors } from "../../../../store/useCases";
import { credentialSelectors } from "../../../../store/credentials";
import { VerificationQR } from "../../../../components/elements/gatacaQRs/VerificationQR";
import Alert from "../../../../components/elements/Alert/Alert";
import { infoAlerts } from "../../../../data/alertsData";

interface IFirstStepProps {
  manageSubStepChange: () => void;
}

const FirstStep: React.FC<IFirstStepProps> = (props) => {
  const { manageSubStepChange } = props;

  // Selectors
  let processId = useSelector(credentialSelectors.getProcessId);
  const stepsData = useSelector(
    useCasesSelectors.getUCIdentityVerificationStepData
  );
  const customQRIcon = useSelector(
    useCasesSelectors.getSelectedUseCaseCustomQRIcon
  );
  const selectedUCFirstStepId = useSelector(
    useCasesSelectors.getSelectedUseCaseSecondStepId
  );

  // States
  const [loadingContent, setLoadingContent] = useState(false);

  // Functions
  const renderGetVerificationQR = () => {
    return (
      <div>
        <VerificationQR
          sessionId={processId}
          goNextStep={manageSubStepChange}
          qrIcon={customQRIcon}
          setLoadingContent={setLoadingContent}
          UCStepId={selectedUCFirstStepId}></VerificationQR>
      </div>
    );
  };

  const renderInfoAlert = () => {
    return <Alert {...infoAlerts.qrScanedBefore}></Alert>;
  };

  return (
    <>
      <StepsContent
        data={!loadingContent ? stepsData.first : stepsData.second}
        QRComponent={renderGetVerificationQR}
        AlertComponent={!loadingContent ? renderInfoAlert : undefined}
      />
    </>
  );
};

export default FirstStep;
