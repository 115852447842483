import React, { useEffect } from "react";
import styles from "./thirdStep.module.scss";
import { useDispatch, useSelector } from "react-redux";
import StepsContent from "../../../../components/elements/stepsContent/stepsContent";
import { useTranslation } from "react-i18next";
import { gaIcons, verifiableIdIcons } from "../../../../data/images";
import VerifiableId from "../../../../components/elements/verifiableId/VerifiableId";
import {
  getUCReceiveCredentialsStepData,
  selectedUseCaseId,
} from "../../../../store/useCases/useCasesSelectors";
import {
  clearCredAndProcessState,
  stopIsCredentialSentPolling,
} from "../../../../store/credentials/credentialActions";
import { deleteSessionStorageData } from "../../../../services/sessionStorageService";
import { hideAlert } from "../../../../store/alerts/alertActions";

interface IUseCardProps {
  goToNextSection: () => void;
}

const ThirdStep: React.FC<IUseCardProps> = (props) => {
  const { goToNextSection } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const stepsData = useSelector(getUCReceiveCredentialsStepData);
  const selectedUseCaseIdStep = useSelector(selectedUseCaseId);

  const navigate = () => {
    goToNextSection();
  };

  useEffect(() => {
    deleteSessionStorageData("ga__process");
    dispatch(clearCredAndProcessState());
    dispatch(stopIsCredentialSentPolling());
    dispatch(hideAlert());
  });

  return (
    <>
      <StepsContent
        data={stepsData.third}
        QRComponent={() => (
          <VerifiableId
            icon={
              selectedUseCaseIdStep === "eventAccess"
                ? verifiableIdIcons.event
                : selectedUseCaseIdStep === "obtainVerifiableID"
                  ? verifiableIdIcons.verifiableId
                  : verifiableIdIcons.iconID
            }
            category={t(
              "useCases." +
                selectedUseCaseIdStep +
                ".receiveCredentials.thirdStep.category"
            )}
            issuer={t(
              "useCases." +
                selectedUseCaseIdStep +
                ".receiveCredentials.thirdStep.issuer"
            )}
            idNumber={t(
              "useCases." +
                selectedUseCaseIdStep +
                ".receiveCredentials.thirdStep.idNumber"
            )}
            iconProcess={gaIcons.iconCheck}
          />
        )}
        buttonAction={navigate}
        classNameBgContainer={styles?.bgContainer}
        classNameQRContainer={styles?.QrContainer}
        classNameMainContainer={styles?.mainContainerBg}
      />
    </>
  );
};

export default ThirdStep;
