import { combineReducers } from "redux";
import { useCasesReducer } from "./useCases";
import { navigationReducer } from "./navigation";
import { credentialReducer } from "./credentials";
import { alertReducer } from "./alerts";

const rootState = {
  useCasesReducer,
  navigationReducer,
  credentialReducer,
  alertReducer,
};

const rootReducer = combineReducers(rootState);

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
