import { FlowStepModel } from "../interfaces/interfaces";

export enum UseCasesFlowSteps {
  getAccess = "get-access",
  identityVerification = "identity-verification",
  receiveCredentials = "receive-credentials",
  requestCredential = "request-credential",
  issuanceComplete = "issuance-complete",
}

export const issuanceUseCaseFlowSteps = [
  UseCasesFlowSteps.receiveCredentials,
  UseCasesFlowSteps.identityVerification,
  UseCasesFlowSteps.requestCredential,
  UseCasesFlowSteps.issuanceComplete,
];

export const verificationUseCaseFlowSteps = [
  UseCasesFlowSteps.receiveCredentials,
  UseCasesFlowSteps.identityVerification,
  UseCasesFlowSteps.getAccess,
];

export const useCasesFlowSteps: FlowStepModel[] = [
  {
    id: UseCasesFlowSteps.requestCredential,
    text: "navigation.requestCredential",
  },
  {
    id: UseCasesFlowSteps.issuanceComplete,
    text: "navigation.issuanceComplete",
  },

  { id: UseCasesFlowSteps.getAccess, text: "navigation.getAccess" },
  {
    id: UseCasesFlowSteps.identityVerification,
    text: "navigation.identityVerification",
  },
  {
    id: UseCasesFlowSteps.receiveCredentials,
    text: "navigation.receiveCredentials",
  },
];

export enum UseCasesNavIds {
  banking = "banking",
  studentId = "student-id",
  hotel = "hotel",
  boardingPass = "boarding-pass",
  securityControl = "security-control",
  event = "event",
  verifiableId = "verifiable-id",
  ePreescription = "e-preescription",
}
