import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./requestCredential.module.scss";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useCasesSelectors } from "../../../store/useCases";
import { setActiveUseCaseStep } from "../../../store/useCases/useCasesActions";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../../store/navigation/navigationActions";
import { viewSteps } from "../data";
import ChevronLeftSVG from "../../../assets/icons/ChevronLeftSVG";
import BackButton from "../../../components/atoms/buttons/back/BackButton";
import {
  deleteSessionStorageData,
  setSessionStorageData,
} from "../../../services/sessionStorageService";
import useSessionStorage from "../../../utils/useSessionStorage.hook";
import { clearCredAndProcessState } from "../../../store/credentials/credentialActions";

const RequestCredential: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Selectors
  const selectedUCSteps = useSelector(
    useCasesSelectors.getSelectedUseCaseSteps
  );
  const selectedUCPath = useSelector(useCasesSelectors.getSelectedUseCasePath);

  // States
  const [activeSubStepIndex, setActiveSubStepIndex] =
    useSessionStorage("ga__subStp");
  const activeStepC = viewSteps[activeSubStepIndex || 0]?.content;

  // Effects
  useEffect(() => {
    !activeSubStepIndex && setActiveSubStepIndex(0);
    selectedUCSteps?.length &&
      selectedUCSteps[2] &&
      (dispatch(
        setSelectedRoute(`/demo/${selectedUCPath}/${selectedUCSteps[2]?.id}`)
      ),
      dispatch(setActiveUseCaseStep(selectedUCSteps[2]?.id)));
  }, [selectedUCSteps]);

  // Functions
  const goToNextSection = () => {
    selectedUCSteps?.length &&
      selectedUCSteps[3] &&
      (setSessionStorageData("ga__subStp", "0"),
      deleteSessionStorageData("ga__process"),
      dispatch(clearCredAndProcessState()),
      dispatch(
        navigateToAction(`/demo/${selectedUCPath}/${selectedUCSteps[3]?.id}`)
      ));
  };

  const goToPreviousStep = () => {
    // This step is not removed just in case it comes in handy again in the future.
    // Right now it is being emulated in step 1
    // setSessionStorageData("ga__subStp", "2");
    setSessionStorageData("ga__subStp", "1");
    selectedUCSteps?.length && selectedUCSteps[1] && activeSubStepIndex === 0
      ? dispatch(
          navigateToAction(`/demo/${selectedUCPath}/${selectedUCSteps[1]?.id}`)
        )
      : setActiveSubStepIndex(activeSubStepIndex - 1);
  };

  const manageSubStepChange = () => {
    const stepsQty = viewSteps?.length;
    activeSubStepIndex === stepsQty - 1
      ? goToNextSection()
      : setActiveSubStepIndex(activeSubStepIndex + 1);
  };

  return (
    <>
      <div className={`${styles?.requestCredential}`}>
        <div className={cx("gridWidth stepMaxWidth")}>
          <div className={cx("useCaseBackContainer")}>
            <BackButton
              label={t("backButton")}
              action={() => goToPreviousStep()}
              IconComponent={<ChevronLeftSVG />}
            />
          </div>
        </div>
        {activeStepC(manageSubStepChange)}
      </div>
    </>
  );
};

export default RequestCredential;
