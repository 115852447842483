import { applyPolyfills, defineCustomElements } from "@gataca/qr/loader";
import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import "./styles/globals.module.scss";
import "./index.scss";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { contentSP } from "./data/csp";
import store from "./store/store";
import { t } from "i18next";

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <div id="main">
    <Helmet>
      <meta http-equiv="Content-Security-Policy" content={contentSP} />
      <title>{t("seo.title")}</title>
      <meta name="title" content={t("seo.title")} data-rh="true"></meta>
      <meta
        name="description"
        content={t("seo.description")}
        data-rh="true"></meta>
      <meta name="keywords" content={t("seo.keywords")} data-rh="true"></meta>
      <meta
        property="og:url"
        content="https://gataca.io/demo"
        data-rh="true"></meta>
      <meta property="og:type" content="website" data-rh="true"></meta>
      <meta property="og:title" content={t("seo.title")} data-rh="true"></meta>
      <meta
        property="og:description"
        content={t("seo.description")}
        data-rh="true"></meta>
    </Helmet>
    <React.StrictMode>
      <Provider store={store()}>
        <App />
      </Provider>
    </React.StrictMode>{" "}
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
