import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./flowFinish.module.scss";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../store/navigation/navigationActions";
import FlowFinishElements from "./components/flowFinishElements";
import { gaImages } from "../../data/images";
import { gatacaContact } from "../../data/globalData";
import { useCasesSelectors } from "../../store/useCases";
import {
  deleteSessionStorageData,
  setSessionStorageData,
} from "../../services/sessionStorageService";
import { ButtonSizes } from "../../data/display";
import {
  clearCredAndProcessState,
  stopIsCredentialSentPolling,
} from "../../store/credentials/credentialActions";

const FlowFinish: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Selectors
  const selectedUCPath = useSelector(useCasesSelectors.getSelectedUseCasePath);

  useEffect(() => {
    dispatch(stopIsCredentialSentPolling());
    deleteSessionStorageData("ga__process");
    dispatch(clearCredAndProcessState());
    setSessionStorageData("ga__subStp", "0");
    dispatch(setSelectedRoute(`/demo/${selectedUCPath}/finish`));
  }, []);

  // Functions
  const goHome = () => {
    dispatch(navigateToAction("/demo"));
  };

  return (
    <div
      className={`${cx("backgroundImageContainer")} ${styles?.flowFinishContainer}`}>
      <div className={`${styles?.flowFinish} ${cx("gridWidth maxWidth")}`}>
        <FlowFinishElements
          title={t("flowFinish.title")}
          description={t("flowFinish.description")}
          contactButton={{
            className: cx("width100"),
            label: t("flowFinish.contactButton"),
            action: () => window.open(gatacaContact, "_blank"),
            size: ButtonSizes.MD,
          }}
          homeButton={{
            className: cx("width100"),
            label: t("flowFinish.homeButton"),
            action: () => goHome(),
            size: ButtonSizes.MD,
          }}
          image={gaImages.bookACall}
        />
      </div>
    </div>
  );
};

export default FlowFinish;
