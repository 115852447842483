import { RESULT_ISSUANCE_STATUS } from "../../interfaces/interfaces";
import {
  CredentialAction,
  CredentialActionTypes,
  ICredentialState,
} from "./credentialTypes";

export const initialRequestsState: ICredentialState = {
  processId: "",
  request: { id: "", status: "", group: "", createdAt: "", updatedAt: "" },
  result: RESULT_ISSUANCE_STATUS.ONGOING,
  credIssuedID: "",
  isFetching: false,
  polling: false,
  pollingId: null,
  credentialSentResult: undefined,
};

export function credentialReducer(
  state: ICredentialState = initialRequestsState,
  action: CredentialAction
): ICredentialState {
  switch (action.type) {
    case CredentialActionTypes.CREATE_ISSUANCE_SESSION:
      return {
        ...state,
        credentialSentResult: undefined,
      };
    case CredentialActionTypes.CREATE_VERIFICATION_SESSION:
      return {
        ...state,
      };
    case CredentialActionTypes.CREATED_SESSION:
      return {
        ...state,
        processId: action.payload.processId,
      };
    case CredentialActionTypes.CREATED_CREDENTIAL:
      return {
        ...state,
        request: action.payload.credentials,
      };
    // POLLING CRED IS SENT
    case CredentialActionTypes.START_IS_CREDENTIAL_SENT_POLLING:
      clearInterval(state.pollingId);
      return {
        ...state,
        polling: true,
        pollingId: setInterval(action.payload.fetch, action.payload.interval),
        credentialSentResult: undefined,
      };
    case CredentialActionTypes.STOP_IS_CREDENTIAL_SENT_POLLING:
      clearInterval(state.pollingId);
      return {
        ...state,
        polling: false,
        pollingId: null,
      };
    case CredentialActionTypes.GET_IS_CREDENTIAL_SENT:
      return { ...state, isFetching: true };
    case CredentialActionTypes.GET_IS_CREDENTIAL_SENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        // @ts-ignore
        credentialSentResult: action?.payload?.result,
      };
    case CredentialActionTypes.GET_IS_CREDENTIAL_SENT_FAILED:
      return {
        ...state,
        isFetching: false,
        credentialSentResult: RESULT_ISSUANCE_STATUS.FAILED,
      };
    case CredentialActionTypes.CLEAR_STATE:
      return { ...initialRequestsState };
    default:
      return state;
  }
}
