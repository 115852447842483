import React from "react";
import "./App.scss";
import Layout from "./components/elements/templates/MainLayout";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

const App: React.FC<any> = () => {
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <Layout></Layout>
      </I18nextProvider>
    </div>
  );
};

export default App;
