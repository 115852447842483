import React from "react";
import styles from "./footer.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { footerMenu } from "./data/data";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles?.footer}>
      <section
        className={`${styles?.mainSectionFooter} ${cx("gridWidth maxWidth")}`}>
        <div className={styles?.policies}>
          <ul>
            {footerMenu?.subRoutes?.map((item, index) => {
              return (
                <li key={"subFooter__" + item.id + index}>
                  <a
                    id={"subFooter__" + item.id}
                    className={cx("buttonSM")}
                    href={item.route || ""}
                    target="_blank">
                    {t(item.label)}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className={`${styles?.rightsReserved} ${cx("bodyRegularSM")}`}
          id={t("footer.rightsReservedGataca")}
          key={"footer__" + t("footer.rightsReservedGataca")}>
          {t("footer.rightsReservedGataca")}
        </div>
      </section>
    </footer>
  );
};

export default Footer;
