export interface IAlertItem {
  kind: ALERT_TYPES;
  title?: string;
  subtitle?: string;
  trailingIcon?: ALERT_ICONS;
  closeButton?: boolean;
  status?: number;
  duration?: number;
}

export enum ALERT_TYPES {
  ERROR = "error",
  INFO = "info",
  // TODO: This will be util in the future
  // SUCCESS = "info-square",
  // WARNING = "warning",
}

export enum ALERT_ICONS {
  ALERT = "alert",
  CHECK = "info",
  CHECK_CIRCLE = "info-square",
  INFO = "warning",
}

export enum AlertActionTypes {
  SHOW_ALERT = "alert/show",
  HIDE_ALERT = "alert/hide",
}

export interface IShowAlertAction {
  type: AlertActionTypes.SHOW_ALERT;
  payload: {
    alert: IAlertItem;
  };
}

export interface IHideAlertAction {
  type: AlertActionTypes.HIDE_ALERT;
}

export interface IAlertState {
  alert?: IAlertItem;
}

export type AlertActions = IShowAlertAction | IHideAlertAction;
