import { RootState } from "../../../src/store/indexReducers";
import { createSelector } from "reselect";
import { ProcessTypes, useCases2 } from "../../data/useCasesData";

import {
  UseCasesFlowSteps,
  issuanceUseCaseFlowSteps,
  useCasesFlowSteps,
  verificationUseCaseFlowSteps,
} from "../../data/flowsData";

export const selectedUseCaseId = (state: RootState) =>
  state.useCasesReducer.selectedUseCase;

export const activeUseCaseStepId = (state: RootState) =>
  state.useCasesReducer.activeUseCaseStep;

export const getSelectedUseCasePath = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    return (
      useCases2?.find((useCase) => useCase?.id === useCaseId)?.path ||
      "use-case"
    );
  }
);

export const getSelectedUseCaseType = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const UCType = getUseCaseType(useCaseId);

    return UCType;
  }
);

export const getSelectedUseCaseCustomQRIcon = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const customQRIcon = useCases2?.find(
      (useCase) => useCase?.id === useCaseId
    )?.customQRIcon;

    return customQRIcon;
  }
);

export const getSelectedUseCaseSteps = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const UCType = getUseCaseType(useCaseId);

    const steps =
      UCType === ProcessTypes.verification
        ? verificationUseCaseFlowSteps
        : UCType === ProcessTypes.issuance
          ? issuanceUseCaseFlowSteps
          : undefined;

    const useCasesFlowSteps = getUseCasesStepsFlowData(steps);
    return useCasesFlowSteps;
  }
);

export const getSelectedUseCaseStepsId = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const selectedUseCase = useCases2?.find(
      (useCase) => useCase?.id === useCaseId
    );

    const firstIssuanceId = selectedUseCase?.receiveCredentials?.id || "";
    const secondIssuanceId = selectedUseCase?.identityVerification?.id || "";
    const thirdIssuanceId = selectedUseCase?.requestCredential?.id || "";

    return {
      first: firstIssuanceId,
      second: secondIssuanceId,
      third: thirdIssuanceId,
    };
  }
);

export const getSelectedUseCaseSecondStepId = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const secondIssuanceId = useCases2?.find(
      (useCase) => useCase?.id === useCaseId
    )?.identityVerification?.id;

    return secondIssuanceId || "";
  }
);

export const getSelectedUseCaseThirdStepId = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const thirdIssuanceId = useCases2?.find(
      (useCase) => useCase?.id === useCaseId
    )?.requestCredential?.id;

    return thirdIssuanceId || "";
  }
);

const getUseCaseType = (id?: string) =>
  useCases2?.find((useCase) => useCase?.id === id)?.type;

const getUseCasesStepsFlowData = (steps?: UseCasesFlowSteps[]) => {
  return steps?.map((step) =>
    useCasesFlowSteps?.find((flowStep) => flowStep && flowStep?.id === step)
  );
};

export const getUCReceiveCredentialsStepData = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const selectedUseCase = useCases2?.find(
      (useCase) => useCase?.id === useCaseId
    );

    const data = selectedUseCase?.receiveCredentials;

    return {
      first: data?.firstStep || "",
      second: data?.secondStep || "",
      third: data?.thirdStep || "",
    };
  }
);

export const getUCRequestCredentialStepData = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const selectedUseCase = useCases2?.find(
      (useCase) => useCase?.id === useCaseId
    );

    const data = selectedUseCase?.requestCredential;

    return {
      first: data?.firstStep || "",
      second: data?.secondStep || "",
    };
  }
);

export const getUCIdentityVerificationStepData = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const selectedUseCase = useCases2?.find(
      (useCase) => useCase?.id === useCaseId
    );

    const data = selectedUseCase?.identityVerification;

    return {
      first: data?.firstStep || "",
      second: data?.secondStep || "",
      third: data?.thirdStep || "",
    };
  }
);

export const getUCLastStepStepData = createSelector(
  [selectedUseCaseId],
  (useCaseId) => {
    const selectedUseCase = useCases2?.find(
      (useCase) => useCase?.id === useCaseId
    );

    const issuanceComplete = selectedUseCase?.issuanceComplete;
    const getAccess = selectedUseCase?.getAccess;

    return {
      issuanceComplete: issuanceComplete || "",
      getAccess: getAccess || "",
    };
  }
);
