import { LinkModel } from "../../../../interfaces/interfaces";

// URLs/URIs

export const privacyPolicyURL = "https://gataca.io/privacy-policy";
export const cookiePolicyURL = "https://gataca.io/cookie-policy";
export const termOfServiceURL = "https://gataca.io/generic-terms-of-service";

export const footerMenu = {
  id: "subFooter",
  label: "subFooter",
  subRoutes: [
    {
      id: "privacyPolicy",
      label: "footer.privacyPolicy",
      route: privacyPolicyURL,
    },
    {
      id: "cookiesPolicy",
      label: "footer.cookiesPolicy",
      route: cookiePolicyURL,
    },
    {
      id: "termsOfUse",
      label: "footer.termsOfUse",
      route: termOfServiceURL,
    },
  ],
};
