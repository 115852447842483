import React, { useEffect } from "react";
import styles from "./home.module.scss";
import cx from "classnames";
import FirstSection from "../sections/firstSection/FirstSection";
import GaImage from "../../../components/atoms/Images/gaImage";
import { gaImages } from "../../../data/images";
import SecondSection from "../sections/secondSection/SecondSection";
import { history } from "../../../services/history";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveUseCaseStep,
  setSelectedUseCase,
} from "../../../store/useCases/useCasesActions";
import { setSelectedRoute } from "../../../store/navigation/navigationActions";
import { navigationSelectors } from "../../../store/navigation";
import {
  clearCredAndProcessState,
  stopIsCredentialSentPolling,
} from "../../../store/credentials/credentialActions";
import {
  deleteSessionStorageData,
  setSessionStorageData,
} from "../../../services/sessionStorageService";

const Home: React.FC = () => {
  const dispatch = useDispatch();

  // Selectors
  const activeRoute = useSelector(navigationSelectors.activeRoute);

  // Effects
  useEffect(() => {
    // When app redirects Home after goes to unknown route
    if (activeRoute !== "/demo") {
      setSessionStorageData("ga__route", "/demo");
      dispatch(setSelectedRoute("/demo"));
      history?.push("/demo");
    }
  }, [activeRoute]);

  useEffect(() => {
    // TODO: LOCK BACK BUTTON IN THIS VIEW?
    dispatch(setSelectedRoute("/demo"));
    history?.push("/demo");

    // Clean use cases and credential states and sessionStorage
    dispatch(stopIsCredentialSentPolling());
    deleteSessionStorageData("ga__scredSkipped");
    deleteSessionStorageData("ga__UC");
    deleteSessionStorageData("ga__process");
    dispatch(clearCredAndProcessState());
    dispatch(setSelectedUseCase(undefined));
    dispatch(setActiveUseCaseStep(undefined));
  }, []);

  return (
    <>
      <div className={`${styles?.home} ${cx("gridWidth maxWidth")}`}>
        <div className={styles?.leftBackground}>
          <GaImage image={gaImages.ellipseBackground} />
        </div>

        <FirstSection />
        <div id="useCases" className={styles.secondSectionContainer}>
          <SecondSection />
        </div>
      </div>
    </>
  );
};

export default Home;
