import { applicableLabels } from "../i18n";
import { UseCaseModel, UseCaseModel2 } from "../interfaces/interfaces";
import { UseCasesNavIds } from "./flowsData";
import { gaQRIcons, useCasesImages } from "./images";

export enum UseCaseCategories {
  all = "all",
  multisector = "multisector",
  government = "government",
  education = "education",
  finance = "finance",
  travel = "travel",
  corporate = "corporate",
  healthcare = "healthcare",
  events = "events",
}

export const useCasesCategories = [
  { text: "home.secondSection.categories.all", id: "all" },
  { text: "home.secondSection.categories.multisector", id: "multisector" },
  { text: "home.secondSection.categories.government", id: "government" },
  { text: "home.secondSection.categories.education", id: "education" },
  { text: "home.secondSection.categories.finance", id: "finance" },
  { text: "home.secondSection.categories.travel", id: "travel" },
  { text: "home.secondSection.categories.corporate", id: "corporate" },
  { text: "home.secondSection.categories.healthcare", id: "healthcare" },
  { text: "home.secondSection.categories.events", id: "events" },
];

// Type of the process of each use case
export enum ProcessTypes {
  issuance = "issuance",
  verification = "verification",
}

export const processTypes = {
  issuance: ProcessTypes.issuance,
  verification: ProcessTypes.verification,
};

// Use Cases Data
export const useCases2: UseCaseModel2[] = [
  {
    id: "openBankAccount",
    categories: [UseCaseCategories.all, UseCaseCategories.finance],
    homeCard: {
      title: "useCases.openBankAccount.homeCard.title",
      by: "useCases.openBankAccount.homeCard.by",
      holder: "useCases.openBankAccount.homeCard.holder",
      verifier: "useCases.openBankAccount.homeCard.verifier",
      image: useCasesImages.openBankAccount.homeCard,
    },
    type: ProcessTypes.issuance,
    path: UseCasesNavIds.banking,
    customQRIcon: gaQRIcons.generalBank,
    receiveCredentials: {
      id: "obtain-verifiable-id",
      firstStep: {
        title: "useCases.openBankAccount.receiveCredentials.firstStep.title",
        button: {
          label:
            "useCases.openBankAccount.receiveCredentials.firstStep.buttonLabel",
        },
        credentialComponent: [
          {
            icon: useCasesImages.openBankAccount.receiveCredentials[0]
              .iconCredentialComponent,
            category:
              "useCases.openBankAccount.receiveCredentials.firstStep.category",
            issuer:
              "useCases.openBankAccount.receiveCredentials.firstStep.issuer",
          },
        ],
        imageBg:
          useCasesImages.openBankAccount.receiveCredentials[0].firstStep[0],
        imageBgStep:
          useCasesImages.openBankAccount.receiveCredentials[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.openBankAccount.receiveCredentials[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.openBankAccount?.receiveCredentials
            ?.firstStep?.descriptionParagraphs,
        descriptionParagraphsQR:
          applicableLabels?.useCases?.openBankAccount?.receiveCredentials
            ?.firstStep?.descriptionParagraphsQR,
      },
      secondStep: {
        title: "useCases.openBankAccount.receiveCredentials.secondStep.title",
        imageBg:
          useCasesImages.openBankAccount.receiveCredentials[0].secondStep[0],
        imageBgStep:
          useCasesImages.openBankAccount.receiveCredentials[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.openBankAccount.receiveCredentials[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.openBankAccount?.receiveCredentials
            ?.secondStep?.descriptionParagraphs,
      },
      thirdStep: {
        title: "useCases.openBankAccount.receiveCredentials.thirdStep.title",
        button: {
          label:
            "useCases.openBankAccount.receiveCredentials.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.openBankAccount.receiveCredentials[0].thirdStep[0],
        imageBgStep:
          useCasesImages.openBankAccount.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.openBankAccount.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.openBankAccount?.receiveCredentials
            ?.thirdStep?.descriptionParagraphs,
      },
    },
    identityVerification: {
      id: "open-bank-account",
      firstStep: {
        title: "useCases.openBankAccount.identityVerification.firstStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.openBankAccount?.identityVerification
            ?.firstStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.openBankAccount.identityVerification[0].firstStep[0],
        imageBgStep:
          useCasesImages.openBankAccount.identityVerification[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.openBankAccount.identityVerification[0].firstStep[2],
      },
      secondStep: {
        title: "useCases.openBankAccount.identityVerification.secondStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.openBankAccount?.identityVerification
            ?.secondStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.openBankAccount.identityVerification[0].secondStep[0],
        imageBgStep:
          useCasesImages.openBankAccount.identityVerification[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.openBankAccount.identityVerification[0].secondStep[2],
      },
      thirdStep: {
        title: "useCases.openBankAccount.identityVerification.thirdStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.openBankAccount?.identityVerification
            ?.thirdStep?.descriptionParagraphs,
        button: {
          label:
            "useCases.openBankAccount.identityVerification.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.openBankAccount.identityVerification[0].thirdStep[0],
        imageBgStep:
          useCasesImages.openBankAccount.identityVerification[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.openBankAccount.identityVerification[0].thirdStep[2],
      },
    },
    requestCredential: {
      id: "open-bank-account",
      firstStep: {
        title: "useCases.openBankAccount.requestCredential.firstStep.title",
        imageBg:
          useCasesImages.openBankAccount.requestCredential[0].firstStep[0],
        imageBgStep:
          useCasesImages.openBankAccount.requestCredential[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.openBankAccount.requestCredential[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.openBankAccount?.requestCredential
            ?.firstStep?.descriptionParagraphs,
      },
      secondStep: {
        title: "useCases.openBankAccount.requestCredential.secondStep.title",
        imageBg:
          useCasesImages.openBankAccount.requestCredential[0].secondStep[0],
        imageBgStep:
          useCasesImages.openBankAccount.requestCredential[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.openBankAccount.requestCredential[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.openBankAccount?.requestCredential
            ?.secondStep?.descriptionParagraphs,
      },
    },
    issuanceComplete: {
      title: "useCases.openBankAccount.issuedCredential.title",
      button: {
        label: "useCases.openBankAccount.issuedCredential.buttonLabel",
      },
      explanation: "useCases.openBankAccount.issuedCredential.explanation",
      credentialComponent: [
        {
          icon: useCasesImages.openBankAccount.issuedCredential
            .iconCredentialComponent,
          category: "useCases.openBankAccount.issuedCredential.category",
          issuer: "useCases.openBankAccount.issuedCredential.issuer",
        },
      ],
      imageBg: useCasesImages.openBankAccount.issuedCredential.bgImages[0],
      imageBgStep: useCasesImages.openBankAccount.issuedCredential.bgImages[1],
      imageBgGatacaLogo:
        useCasesImages.openBankAccount.issuedCredential.bgImages[2],
      descriptionParagraphs:
        applicableLabels?.useCases?.openBankAccount?.issuedCredential
          ?.descriptionParagraphs,
      descriptionParagraphsQR:
        applicableLabels?.useCases?.openBankAccount?.issuedCredential
          ?.descriptionParagraphsQR,
    },
  },
  {
    id: "obtainStudentId",
    categories: [UseCaseCategories.all, UseCaseCategories.education],
    homeCard: {
      title: "useCases.obtainStudentId.homeCard.title",
      by: "useCases.obtainStudentId.homeCard.by",
      holder: "useCases.obtainStudentId.homeCard.holder",
      verifier: "useCases.obtainStudentId.homeCard.verifier",
      image: useCasesImages.obtainStudentId.homeCard,
    },
    type: ProcessTypes.issuance,
    path: UseCasesNavIds.studentId,
    customQRIcon: gaQRIcons.university,

    ////////
    receiveCredentials: {
      id: "obtain-verifiable-id",
      firstStep: {
        title: "useCases.obtainStudentId.receiveCredentials.firstStep.title",
        button: {
          label:
            "useCases.obtainStudentId.receiveCredentials.firstStep.buttonLabel",
        },
        credentialComponent: [
          {
            icon: useCasesImages.obtainStudentId.receiveCredentials[0]
              .iconCredentialComponent,
            category:
              "useCases.obtainStudentId.receiveCredentials.firstStep.category",
            issuer:
              "useCases.obtainStudentId.receiveCredentials.firstStep.issuer",
          },
        ],
        imageBg:
          useCasesImages.obtainStudentId.receiveCredentials[0].firstStep[0],
        imageBgStep:
          useCasesImages.obtainStudentId.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainStudentId.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainStudentId?.receiveCredentials
            ?.firstStep?.descriptionParagraphs,
        descriptionParagraphsQR:
          applicableLabels?.useCases?.obtainStudentId?.receiveCredentials
            ?.firstStep?.descriptionParagraphsQR,
      },
      secondStep: {
        title: "useCases.obtainStudentId.receiveCredentials.secondStep.title",
        imageBg:
          useCasesImages.obtainStudentId.receiveCredentials[0].secondStep[0],
        imageBgStep:
          useCasesImages.obtainStudentId.receiveCredentials[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainStudentId.receiveCredentials[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainStudentId?.receiveCredentials
            ?.secondStep?.descriptionParagraphs,
      },
      thirdStep: {
        title: "useCases.obtainStudentId.receiveCredentials.thirdStep.title",
        button: {
          label:
            "useCases.obtainStudentId.receiveCredentials.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.obtainStudentId.receiveCredentials[0].thirdStep[0],
        imageBgStep:
          useCasesImages.obtainStudentId.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainStudentId.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainStudentId?.receiveCredentials
            ?.thirdStep?.descriptionParagraphs,
      },
    },
    identityVerification: {
      id: "obtain-student-id",
      firstStep: {
        title: "useCases.obtainStudentId.identityVerification.firstStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainStudentId?.identityVerification
            ?.firstStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.obtainStudentId.identityVerification[0].firstStep[0],
        imageBgStep:
          useCasesImages.obtainStudentId.identityVerification[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainStudentId.identityVerification[0].firstStep[2],
      },
      secondStep: {
        title: "useCases.obtainStudentId.identityVerification.secondStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainStudentId?.identityVerification
            ?.secondStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.obtainStudentId.identityVerification[0].secondStep[0],
        imageBgStep:
          useCasesImages.obtainStudentId.identityVerification[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainStudentId.identityVerification[0].secondStep[2],
      },
      thirdStep: {
        title: "useCases.obtainStudentId.identityVerification.thirdStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainStudentId?.identityVerification
            ?.thirdStep?.descriptionParagraphs,
        button: {
          label:
            "useCases.obtainStudentId.identityVerification.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.obtainStudentId.identityVerification[0].thirdStep[0],
        imageBgStep:
          useCasesImages.obtainStudentId.identityVerification[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainStudentId.identityVerification[0].thirdStep[2],
      },
    },
    requestCredential: {
      id: "obtain-student-id",
      firstStep: {
        title: "useCases.obtainStudentId.requestCredential.firstStep.title",
        imageBg:
          useCasesImages.obtainStudentId.requestCredential[0].firstStep[0],
        imageBgStep:
          useCasesImages.obtainStudentId.requestCredential[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainStudentId.requestCredential[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainStudentId?.requestCredential
            ?.firstStep?.descriptionParagraphs,
      },
      secondStep: {
        title: "useCases.obtainStudentId.requestCredential.secondStep.title",
        imageBg:
          useCasesImages.obtainStudentId.requestCredential[0].secondStep[0],
        imageBgStep:
          useCasesImages.obtainStudentId.requestCredential[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainStudentId.requestCredential[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainStudentId?.requestCredential
            ?.secondStep?.descriptionParagraphs,
      },
    },
    issuanceComplete: {
      title: "useCases.obtainStudentId.issuedCredential.title",
      button: {
        label: "useCases.obtainStudentId.issuedCredential.buttonLabel",
      },
      credentialComponent: [
        {
          icon: useCasesImages.obtainStudentId.issuedCredential
            .iconCredentialComponent,
          category: "useCases.obtainStudentId.issuedCredential.category",
          issuer: "useCases.obtainStudentId.issuedCredential.issuer",
        },
      ],
      imageBg: useCasesImages.obtainStudentId.issuedCredential.bgImages[0],
      imageBgStep: useCasesImages.obtainStudentId.issuedCredential.bgImages[1],
      imageBgGatacaLogo:
        useCasesImages.obtainStudentId.issuedCredential.bgImages[2],
      descriptionParagraphs:
        applicableLabels?.useCases?.obtainStudentId?.issuedCredential
          ?.descriptionParagraphs,
      descriptionParagraphsQR:
        applicableLabels?.useCases?.obtainStudentId?.issuedCredential
          ?.descriptionParagraphsQR,
    },
  },
  {
    id: "hotelCheckIn",
    categories: [UseCaseCategories.all, UseCaseCategories.travel],
    homeCard: {
      title: "useCases.hotelCheckIn.homeCard.title",
      by: "useCases.hotelCheckIn.homeCard.by",
      holder: "useCases.hotelCheckIn.homeCard.holder",
      verifier: "useCases.hotelCheckIn.homeCard.verifier",
      image: useCasesImages.hotelCheckIn.homeCard,
    },
    type: ProcessTypes.issuance,

    path: UseCasesNavIds.hotel,
    customQRIcon: gaQRIcons.hotel,

    receiveCredentials: {
      id: "obtain-verifiable-id",
      firstStep: {
        title: "useCases.hotelCheckIn.receiveCredentials.firstStep.title",
        button: {
          label:
            "useCases.hotelCheckIn.receiveCredentials.firstStep.buttonLabel",
        },
        credentialComponent: [
          {
            icon: useCasesImages.hotelCheckIn.receiveCredentials[0]
              .iconCredentialComponent,
            category:
              "useCases.hotelCheckIn.receiveCredentials.firstStep.category",
            issuer: "useCases.hotelCheckIn.receiveCredentials.firstStep.issuer",
          },
        ],
        imageBg: useCasesImages.hotelCheckIn.receiveCredentials[0].firstStep[0],
        imageBgStep:
          useCasesImages.hotelCheckIn.receiveCredentials[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.hotelCheckIn.receiveCredentials[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.hotelCheckIn?.receiveCredentials
            ?.firstStep?.descriptionParagraphs,
        descriptionParagraphsQR:
          applicableLabels?.useCases?.hotelCheckIn?.receiveCredentials
            ?.firstStep?.descriptionParagraphsQR,
      },
      secondStep: {
        title: "useCases.hotelCheckIn.receiveCredentials.secondStep.title",
        imageBg:
          useCasesImages.hotelCheckIn.receiveCredentials[0].secondStep[0],
        imageBgStep:
          useCasesImages.hotelCheckIn.receiveCredentials[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.hotelCheckIn.receiveCredentials[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.hotelCheckIn?.receiveCredentials
            ?.secondStep?.descriptionParagraphs,
      },
      thirdStep: {
        title: "useCases.hotelCheckIn.receiveCredentials.thirdStep.title",
        button: {
          label:
            "useCases.hotelCheckIn.receiveCredentials.thirdStep.buttonLabel",
        },
        imageBg: useCasesImages.hotelCheckIn.receiveCredentials[0].thirdStep[0],
        imageBgStep:
          useCasesImages.hotelCheckIn.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.hotelCheckIn.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.hotelCheckIn?.receiveCredentials
            ?.thirdStep?.descriptionParagraphs,
      },
    },
    identityVerification: {
      id: "hotel-check-in",
      firstStep: {
        title: "useCases.hotelCheckIn.identityVerification.firstStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.hotelCheckIn?.identityVerification
            ?.firstStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.hotelCheckIn.identityVerification[0].firstStep[0],
        imageBgStep:
          useCasesImages.hotelCheckIn.identityVerification[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.hotelCheckIn.identityVerification[0].firstStep[2],
      },
      secondStep: {
        title: "useCases.hotelCheckIn.identityVerification.secondStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.hotelCheckIn?.identityVerification
            ?.secondStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.hotelCheckIn.identityVerification[0].secondStep[0],
        imageBgStep:
          useCasesImages.hotelCheckIn.identityVerification[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.hotelCheckIn.identityVerification[0].secondStep[2],
      },
      thirdStep: {
        title: "useCases.hotelCheckIn.identityVerification.thirdStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.hotelCheckIn?.identityVerification
            ?.thirdStep?.descriptionParagraphs,
        button: {
          label:
            "useCases.hotelCheckIn.identityVerification.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.hotelCheckIn.identityVerification[0].thirdStep[0],
        imageBgStep:
          useCasesImages.hotelCheckIn.identityVerification[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.hotelCheckIn.identityVerification[0].thirdStep[2],
      },
    },
    requestCredential: {
      id: "hotel-check-in",
      firstStep: {
        title: "useCases.hotelCheckIn.requestCredential.firstStep.title",
        imageBg: useCasesImages.hotelCheckIn.requestCredential[0].firstStep[0],
        imageBgStep:
          useCasesImages.hotelCheckIn.requestCredential[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.hotelCheckIn.requestCredential[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.hotelCheckIn?.requestCredential?.firstStep
            ?.descriptionParagraphs,
      },
      secondStep: {
        title: "useCases.hotelCheckIn.requestCredential.secondStep.title",
        imageBg: useCasesImages.hotelCheckIn.requestCredential[0].secondStep[0],
        imageBgStep:
          useCasesImages.hotelCheckIn.requestCredential[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.hotelCheckIn.requestCredential[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.hotelCheckIn?.requestCredential
            ?.secondStep?.descriptionParagraphs,
      },
    },
    issuanceComplete: {
      title: "useCases.hotelCheckIn.issuedCredential.title",
      button: {
        label: "useCases.hotelCheckIn.issuedCredential.buttonLabel",
      },
      explanation: "useCases.hotelCheckIn.issuedCredential.explanation",
      credentialComponent: [
        {
          icon: useCasesImages.hotelCheckIn.issuedCredential
            .iconCredentialComponent,
          category: "useCases.hotelCheckIn.issuedCredential.category",
          issuer: "useCases.hotelCheckIn.issuedCredential.issuer",
        },
      ],
      imageBg: useCasesImages.hotelCheckIn.issuedCredential.bgImages[0],
      imageBgStep: useCasesImages.hotelCheckIn.issuedCredential.bgImages[1],
      imageBgGatacaLogo:
        useCasesImages.hotelCheckIn.issuedCredential.bgImages[2],
      descriptionParagraphs:
        applicableLabels?.useCases?.hotelCheckIn?.issuedCredential
          ?.descriptionParagraphs,
      descriptionParagraphsQR:
        applicableLabels?.useCases?.hotelCheckIn?.issuedCredential
          ?.descriptionParagraphsQR,
    },
  },
  // TODO: Add more than one credential
  {
    id: "boardingPass",
    categories: [UseCaseCategories.all, UseCaseCategories.travel],
    homeCard: {
      title: "useCases.boardingPass.homeCard.title",
      by: "useCases.boardingPass.homeCard.by",
      holder: "useCases.boardingPass.homeCard.holder",
      verifier: "useCases.boardingPass.homeCard.verifier",
      image: useCasesImages.boardingPass.homeCard,
    },
    type: ProcessTypes.issuance,

    path: UseCasesNavIds.boardingPass,
    customQRIcon: gaQRIcons.onlineAirline,
    receiveCredentials: {
      id: "obtain-verifiable-id",
      firstStep: {
        title: "useCases.boardingPass.receiveCredentials.firstStep.title",
        button: {
          label:
            "useCases.boardingPass.receiveCredentials.firstStep.buttonLabel",
        },
        credentialComponent: [
          {
            icon: useCasesImages.boardingPass.receiveCredentials[0]
              .iconCredentialComponentFirst,
            category:
              "useCases.boardingPass.receiveCredentials.firstStep.categoryFirst",
            issuer: "useCases.boardingPass.receiveCredentials.firstStep.issuer",
          },
          {
            icon: useCasesImages.boardingPass.receiveCredentials[0]
              .iconCredentialComponentSecond,
            category:
              "useCases.boardingPass.receiveCredentials.firstStep.categorySecond",
            issuer: "useCases.boardingPass.receiveCredentials.firstStep.issuer",
          },
        ],
        imageBg: useCasesImages.boardingPass.receiveCredentials[0].firstStep[0],
        imageBgStep:
          useCasesImages.boardingPass.receiveCredentials[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.receiveCredentials[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.receiveCredentials
            ?.firstStep?.descriptionParagraphs,
        descriptionParagraphsQR:
          applicableLabels?.useCases?.boardingPass?.receiveCredentials
            ?.firstStep?.descriptionParagraphsQR,
      },
      secondStep: {
        title: "useCases.boardingPass.receiveCredentials.secondStep.title",
        imageBg:
          useCasesImages.boardingPass.receiveCredentials[0].secondStep[0],
        imageBgStep:
          useCasesImages.boardingPass.receiveCredentials[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.receiveCredentials[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.receiveCredentials
            ?.secondStep?.descriptionParagraphs,
      },
      thirdStep: {
        title: "useCases.boardingPass.receiveCredentials.thirdStep.title",
        button: {
          label:
            "useCases.boardingPass.receiveCredentials.thirdStep.buttonLabel",
        },
        imageBg: useCasesImages.boardingPass.receiveCredentials[0].thirdStep[0],
        imageBgStep:
          useCasesImages.boardingPass.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.receiveCredentials
            ?.thirdStep?.descriptionParagraphs,
      },
    },
    identityVerification: {
      id: "boarding-pass",
      firstStep: {
        title: "useCases.boardingPass.identityVerification.firstStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.identityVerification
            ?.firstStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.boardingPass.identityVerification[0].firstStep[0],
        imageBgStep:
          useCasesImages.boardingPass.identityVerification[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.identityVerification[0].firstStep[2],
      },
      secondStep: {
        title: "useCases.boardingPass.identityVerification.secondStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.identityVerification
            ?.secondStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.boardingPass.identityVerification[0].secondStep[0],
        imageBgStep:
          useCasesImages.boardingPass.identityVerification[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.identityVerification[0].secondStep[2],
      },
      thirdStep: {
        title: "useCases.boardingPass.identityVerification.thirdStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.identityVerification
            ?.thirdStep?.descriptionParagraphs,
        button: {
          label:
            "useCases.boardingPass.identityVerification.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.boardingPass.identityVerification[0].thirdStep[0],
        imageBgStep:
          useCasesImages.boardingPass.identityVerification[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.identityVerification[0].thirdStep[2],
      },
    },
    requestCredential: {
      id: "boarding-pass",
      firstStep: {
        title: "useCases.boardingPass.requestCredential.firstStep.title",
        imageBg: useCasesImages.boardingPass.requestCredential[0].firstStep[0],
        imageBgStep:
          useCasesImages.boardingPass.requestCredential[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.requestCredential[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.requestCredential?.firstStep
            ?.descriptionParagraphs,
      },
      secondStep: {
        title: "useCases.boardingPass.requestCredential.secondStep.title",
        imageBg: useCasesImages.boardingPass.requestCredential[0].secondStep[0],
        imageBgStep:
          useCasesImages.boardingPass.requestCredential[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.requestCredential[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.requestCredential
            ?.secondStep?.descriptionParagraphs,
      },
    },
    issuanceComplete: {
      title: "useCases.boardingPass.issuedCredential.title",
      button: {
        label: "useCases.boardingPass.issuedCredential.buttonLabel",
      },
      explanation: "useCases.boardingPass.issuedCredential.explanation",
      credentialComponent: [
        {
          icon: useCasesImages.boardingPass.issuedCredential
            .iconCredentialComponent,
          category: "useCases.boardingPass.issuedCredential.category",
          issuer: "useCases.boardingPass.issuedCredential.issuer",
        },
      ],
      imageBg: useCasesImages.boardingPass.issuedCredential.bgImages[0],
      imageBgStep: useCasesImages.boardingPass.issuedCredential.bgImages[1],
      imageBgGatacaLogo:
        useCasesImages.boardingPass.issuedCredential.bgImages[2],
      descriptionParagraphs:
        applicableLabels?.useCases?.boardingPass?.issuedCredential
          ?.descriptionParagraphs,
      descriptionParagraphsQR:
        applicableLabels?.useCases?.boardingPass?.issuedCredential
          ?.descriptionParagraphsQR,
    },
  },
  {
    id: "airportControl",
    categories: [UseCaseCategories.all, UseCaseCategories.travel],
    homeCard: {
      title: "useCases.airportControl.homeCard.title",
      by: "useCases.airportControl.homeCard.by",
      holder: "useCases.airportControl.homeCard.holder",
      verifier: "useCases.airportControl.homeCard.verifier",
      image: useCasesImages.airportControl.homeCard,
    },
    type: ProcessTypes.verification,

    path: UseCasesNavIds.securityControl,
    customQRIcon: gaQRIcons.nationalPolice,
    receiveCredentials: {
      id: "obtain-verifiable-id",
      firstStep: {
        title: "useCases.airportControl.receiveCredentials.firstStep.title",
        button: {
          label:
            "useCases.airportControl.receiveCredentials.firstStep.buttonLabel",
        },
        credentialComponent: [
          {
            icon: useCasesImages.airportControl.receiveCredentials[0]
              .iconCredentialComponent,
            category:
              "useCases.airportControl.receiveCredentials.firstStep.category",
            issuer:
              "useCases.airportControl.receiveCredentials.firstStep.issuer",
          },
        ],
        imageBg:
          useCasesImages.airportControl.receiveCredentials[0].firstStep[0],
        imageBgStep:
          useCasesImages.airportControl.receiveCredentials[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.airportControl.receiveCredentials[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.airportControl?.receiveCredentials
            ?.firstStep?.descriptionParagraphs,
        descriptionParagraphsQR:
          applicableLabels?.useCases?.airportControl?.receiveCredentials
            ?.firstStep?.descriptionParagraphsQR,
      },
      secondStep: {
        title: "useCases.airportControl.receiveCredentials.secondStep.title",
        imageBg:
          useCasesImages.airportControl.receiveCredentials[0].secondStep[0],
        imageBgStep:
          useCasesImages.airportControl.receiveCredentials[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.airportControl.receiveCredentials[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.airportControl?.receiveCredentials
            ?.secondStep?.descriptionParagraphs,
      },
      thirdStep: {
        title: "useCases.airportControl.receiveCredentials.thirdStep.title",
        button: {
          label:
            "useCases.airportControl.receiveCredentials.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.airportControl.receiveCredentials[0].thirdStep[0],
        imageBgStep:
          useCasesImages.airportControl.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.airportControl.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.airportControl?.receiveCredentials
            ?.thirdStep?.descriptionParagraphs,
      },
    },
    identityVerification: {
      id: "airport-control",
      firstStep: {
        title: "useCases.airportControl.identityVerification.firstStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.airportControl?.identityVerification
            ?.firstStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.airportControl.identityVerification[0].firstStep[0],
        imageBgStep:
          useCasesImages.airportControl.identityVerification[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.airportControl.identityVerification[0].firstStep[2],
      },
      secondStep: {
        title: "useCases.airportControl.identityVerification.secondStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.airportControl?.identityVerification
            ?.secondStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.airportControl.identityVerification[0].secondStep[0],
        imageBgStep:
          useCasesImages.airportControl.identityVerification[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.airportControl.identityVerification[0].secondStep[2],
      },
    },
    requestCredential: {
      id: "airport-control",
      firstStep: {
        title: "useCases.boardingPass.requestCredential.firstStep.title",
        imageBg: useCasesImages.boardingPass.requestCredential[0].firstStep[0],
        imageBgStep:
          useCasesImages.boardingPass.requestCredential[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.requestCredential[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.requestCredential?.firstStep
            ?.descriptionParagraphs,
      },
      secondStep: {
        title: "useCases.boardingPass.requestCredential.secondStep.title",
        imageBg: useCasesImages.boardingPass.requestCredential[0].secondStep[0],
        imageBgStep:
          useCasesImages.boardingPass.requestCredential[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.requestCredential[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.requestCredential
            ?.secondStep?.descriptionParagraphs,
      },
    },
    getAccess: {
      title: "useCases.airportControl.getAccess.title",
      button: {
        label: "useCases.airportControl.getAccess.buttonLabel",
      },
      imageBg: useCasesImages.airportControl.getAccess.bgImages[0],
      imageBgStep: useCasesImages.airportControl.getAccess.bgImages[1],
      imageBgGatacaLogo: useCasesImages.airportControl.getAccess.bgImages[2],
      descriptionParagraphs:
        applicableLabels?.useCases?.airportControl?.getAccess
          ?.descriptionParagraphs,
    },
  },
  {
    id: "eventAccess",
    categories: [UseCaseCategories.all, UseCaseCategories.events],
    homeCard: {
      title: "useCases.eventAccess.homeCard.title",
      by: "useCases.eventAccess.homeCard.by",
      holder: "useCases.eventAccess.homeCard.holder",
      verifier: "useCases.eventAccess.homeCard.verifier",
      image: useCasesImages.eventAccess.homeCard,
    },
    type: ProcessTypes.verification,

    path: UseCasesNavIds.event,
    customQRIcon: gaQRIcons.eventOrganization,
    receiveCredentials: {
      id: "conference-ticket",
      firstStep: {
        title: "useCases.eventAccess.receiveCredentials.firstStep.title",
        button: {
          label:
            "useCases.eventAccess.receiveCredentials.firstStep.buttonLabel",
        },
        credentialComponent: [
          {
            icon: useCasesImages.eventAccess.receiveCredentials[0]
              .iconCredentialComponent,
            category:
              "useCases.eventAccess.receiveCredentials.firstStep.category",
            issuer: "useCases.eventAccess.receiveCredentials.firstStep.issuer",
          },
        ],
        imageBg: useCasesImages.eventAccess.receiveCredentials[0].firstStep[0],
        imageBgStep:
          useCasesImages.eventAccess.receiveCredentials[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.eventAccess.receiveCredentials[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.eventAccess?.receiveCredentials?.firstStep
            ?.descriptionParagraphs,
        descriptionParagraphsQR:
          applicableLabels?.useCases?.eventAccess?.receiveCredentials?.firstStep
            ?.descriptionParagraphsQR,
      },
      secondStep: {
        title: "useCases.eventAccess.receiveCredentials.secondStep.title",
        imageBg: useCasesImages.eventAccess.receiveCredentials[0].secondStep[0],
        imageBgStep:
          useCasesImages.eventAccess.receiveCredentials[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.eventAccess.receiveCredentials[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.eventAccess?.receiveCredentials
            ?.secondStep?.descriptionParagraphs,
      },
      thirdStep: {
        title: "useCases.eventAccess.receiveCredentials.thirdStep.title",
        button: {
          label:
            "useCases.eventAccess.receiveCredentials.thirdStep.buttonLabel",
        },
        imageBg: useCasesImages.eventAccess.receiveCredentials[0].thirdStep[0],
        imageBgStep:
          useCasesImages.eventAccess.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.eventAccess.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.eventAccess?.receiveCredentials?.thirdStep
            ?.descriptionParagraphs,
      },
    },
    identityVerification: {
      id: "conference-ticket",
      firstStep: {
        title: "useCases.eventAccess.identityVerification.firstStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.eventAccess?.identityVerification
            ?.firstStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.eventAccess.identityVerification[0].firstStep[0],
        imageBgStep:
          useCasesImages.eventAccess.identityVerification[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.eventAccess.identityVerification[0].firstStep[2],
      },
      secondStep: {
        title: "useCases.eventAccess.identityVerification.secondStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.eventAccess?.identityVerification
            ?.secondStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.eventAccess.identityVerification[0].secondStep[0],
        imageBgStep:
          useCasesImages.eventAccess.identityVerification[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.eventAccess.identityVerification[0].secondStep[2],
      },
    },
    requestCredential: {
      id: "conference-ticket",
      firstStep: {
        title: "useCases.boardingPass.requestCredential.firstStep.title",
        imageBg: useCasesImages.boardingPass.requestCredential[0].firstStep[0],
        imageBgStep:
          useCasesImages.boardingPass.requestCredential[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.requestCredential[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.requestCredential?.firstStep
            ?.descriptionParagraphs,
      },
      secondStep: {
        title: "useCases.boardingPass.requestCredential.secondStep.title",
        imageBg: useCasesImages.boardingPass.requestCredential[0].secondStep[0],
        imageBgStep:
          useCasesImages.boardingPass.requestCredential[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.boardingPass.requestCredential[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.boardingPass?.requestCredential
            ?.secondStep?.descriptionParagraphs,
      },
    },
    getAccess: {
      title: "useCases.eventAccess.getAccess.title",
      button: {
        label: "useCases.eventAccess.getAccess.buttonLabel",
      },
      imageBg: useCasesImages.eventAccess.getAccess.bgImages[0],
      imageBgStep: useCasesImages.eventAccess.getAccess.bgImages[1],
      imageBgGatacaLogo: useCasesImages.eventAccess.getAccess.bgImages[2],
      descriptionParagraphs:
        applicableLabels?.useCases?.eventAccess?.getAccess
          ?.descriptionParagraphs,
    },
  },
  {
    id: "obtainVerifiableID",
    categories: [UseCaseCategories.all, UseCaseCategories.government],
    homeCard: {
      title: "useCases.obtainVerifiableID.homeCard.title",
      by: "useCases.obtainVerifiableID.homeCard.by",
      holder: "useCases.obtainVerifiableID.homeCard.holder",
      verifier: "useCases.obtainVerifiableID.homeCard.verifier",
      image: useCasesImages.obtainVerifiableID.homeCard,
    },
    type: ProcessTypes.issuance,
    path: UseCasesNavIds.verifiableId,
    customQRIcon: gaQRIcons.government,
    receiveCredentials: {
      id: "birth-certificate",
      firstStep: {
        title: "useCases.obtainVerifiableID.receiveCredentials.firstStep.title",
        button: {
          label:
            "useCases.obtainVerifiableID.receiveCredentials.firstStep.buttonLabel",
        },
        credentialComponent: [
          {
            icon: useCasesImages.obtainVerifiableID.receiveCredentials[0]
              .iconCredentialComponent,
            category:
              "useCases.obtainVerifiableID.receiveCredentials.firstStep.category",
            issuer:
              "useCases.obtainVerifiableID.receiveCredentials.firstStep.issuer",
          },
        ],
        imageBg:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].firstStep[0],
        imageBgStep:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainVerifiableID?.receiveCredentials
            ?.firstStep?.descriptionParagraphs,
        descriptionParagraphsQR:
          applicableLabels?.useCases?.obtainVerifiableID.receiveCredentials
            ?.firstStep?.descriptionParagraphsQR,
      },
      secondStep: {
        title:
          "useCases.obtainVerifiableID.receiveCredentials.secondStep.title",
        imageBg:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].secondStep[0],
        imageBgStep:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainVerifiableID?.receiveCredentials
            ?.secondStep?.descriptionParagraphs,
      },
      thirdStep: {
        title: "useCases.obtainVerifiableID.receiveCredentials.thirdStep.title",
        button: {
          label:
            "useCases.obtainVerifiableID.receiveCredentials.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].thirdStep[0],
        imageBgStep:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainVerifiableID.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainVerifiableID?.receiveCredentials
            ?.thirdStep?.descriptionParagraphs,
      },
    },
    identityVerification: {
      id: "birth-cert-verifiable-id",
      firstStep: {
        title:
          "useCases.obtainVerifiableID.identityVerification.firstStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainVerifiableID?.identityVerification
            ?.firstStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .firstStep[0],
        imageBgStep:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .firstStep[2],
      },
      secondStep: {
        title:
          "useCases.obtainVerifiableID.identityVerification.secondStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainVerifiableID?.identityVerification
            ?.secondStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .secondStep[0],
        imageBgStep:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .secondStep[2],
      },
      thirdStep: {
        title:
          "useCases.obtainVerifiableID.identityVerification.thirdStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainVerifiableID?.identityVerification
            ?.thirdStep?.descriptionParagraphs,
        button: {
          label:
            "useCases.obtainVerifiableID.identityVerification.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .thirdStep[0],
        imageBgStep:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainVerifiableID.identityVerification[0]
            .thirdStep[2],
      },
    },
    requestCredential: {
      id: "birth-cert-verifiable-id",
      firstStep: {
        title: "useCases.obtainVerifiableID.requestCredential.firstStep.title",
        imageBg:
          useCasesImages.obtainVerifiableID.requestCredential[0].firstStep[0],
        imageBgStep:
          useCasesImages.obtainVerifiableID.requestCredential[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainVerifiableID.requestCredential[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainVerifiableID?.requestCredential
            ?.firstStep?.descriptionParagraphs,
      },
      secondStep: {
        title: "useCases.obtainVerifiableID.requestCredential.secondStep.title",
        imageBg:
          useCasesImages.obtainVerifiableID.requestCredential[0].secondStep[0],
        imageBgStep:
          useCasesImages.obtainVerifiableID.requestCredential[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.obtainVerifiableID.requestCredential[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.obtainVerifiableID?.requestCredential
            ?.secondStep?.descriptionParagraphs,
      },
    },
    issuanceComplete: {
      title: "useCases.obtainVerifiableID.issuedCredential.title",
      button: {
        label: "useCases.obtainVerifiableID.issuedCredential.buttonLabel",
      },
      explanation: "useCases.obtainVerifiableID.issuedCredential.explanation",
      credentialComponent: [
        {
          icon: useCasesImages.obtainVerifiableID.issuedCredential
            .iconCredentialComponent,
          category: "useCases.obtainVerifiableID.issuedCredential.category",
          issuer: "useCases.obtainVerifiableID.issuedCredential.issuer",
        },
      ],
      imageBg: useCasesImages.obtainVerifiableID.issuedCredential.bgImages[0],
      imageBgStep:
        useCasesImages.obtainVerifiableID.issuedCredential.bgImages[1],
      imageBgGatacaLogo:
        useCasesImages.obtainVerifiableID.issuedCredential.bgImages[2],
      descriptionParagraphs:
        applicableLabels?.useCases?.obtainVerifiableID?.issuedCredential
          ?.descriptionParagraphs,
      descriptionParagraphsQR:
        applicableLabels?.useCases?.obtainVerifiableID?.issuedCredential
          ?.descriptionParagraphsQR,
    },
  },
  {
    id: "ePrescription",
    categories: [UseCaseCategories.all, UseCaseCategories.healthcare],
    homeCard: {
      title: "useCases.ePrescription.homeCard.title",
      by: "useCases.ePrescription.homeCard.by",
      holder: "useCases.ePrescription.homeCard.holder",
      verifier: "useCases.ePrescription.homeCard.verifier",
      image: useCasesImages.ePrescription.homeCard,
    },
    type: ProcessTypes.issuance,
    path: UseCasesNavIds.ePreescription,
    customQRIcon: gaQRIcons.healthcare,
    receiveCredentials: {
      id: "obtain-verifiable-id",
      firstStep: {
        title: "useCases.ePrescription.receiveCredentials.firstStep.title",
        button: {
          label:
            "useCases.ePrescription.receiveCredentials.firstStep.buttonLabel",
        },
        credentialComponent: [
          {
            icon: useCasesImages.ePrescription.receiveCredentials[0]
              .iconCredentialComponent,
            category:
              "useCases.ePrescription.receiveCredentials.firstStep.category",
            issuer:
              "useCases.ePrescription.receiveCredentials.firstStep.issuer",
          },
        ],
        imageBg:
          useCasesImages.ePrescription.receiveCredentials[0].firstStep[0],
        imageBgStep:
          useCasesImages.ePrescription.receiveCredentials[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.ePrescription.receiveCredentials[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.ePrescription?.receiveCredentials
            ?.firstStep?.descriptionParagraphs,
        descriptionParagraphsQR:
          applicableLabels?.useCases?.ePrescription?.receiveCredentials
            ?.firstStep?.descriptionParagraphsQR,
      },
      secondStep: {
        title: "useCases.ePrescription.receiveCredentials.secondStep.title",
        imageBg:
          useCasesImages.ePrescription.receiveCredentials[0].secondStep[0],
        imageBgStep:
          useCasesImages.ePrescription.receiveCredentials[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.ePrescription.receiveCredentials[0].secondStep[2],

        descriptionParagraphs:
          applicableLabels?.useCases?.ePrescription?.receiveCredentials
            ?.secondStep?.descriptionParagraphs,
      },
      thirdStep: {
        title: "useCases.ePrescription.receiveCredentials.thirdStep.title",
        button: {
          label:
            "useCases.ePrescription.receiveCredentials.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.ePrescription.receiveCredentials[0].thirdStep[0],
        imageBgStep:
          useCasesImages.ePrescription.receiveCredentials[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.ePrescription.receiveCredentials[0].thirdStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.ePrescription?.receiveCredentials
            ?.thirdStep?.descriptionParagraphs,
      },
    },
    identityVerification: {
      id: "e-prescription",
      firstStep: {
        title: "useCases.ePrescription.identityVerification.firstStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.ePrescription?.identityVerification
            ?.firstStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.ePrescription.identityVerification[0].firstStep[0],
        imageBgStep:
          useCasesImages.ePrescription.identityVerification[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.ePrescription.identityVerification[0].firstStep[2],
      },
      secondStep: {
        title: "useCases.ePrescription.identityVerification.secondStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.ePrescription?.identityVerification
            ?.secondStep?.descriptionParagraphs,
        imageBg:
          useCasesImages.ePrescription.identityVerification[0].secondStep[0],
        imageBgStep:
          useCasesImages.ePrescription.identityVerification[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.ePrescription.identityVerification[0].secondStep[2],
      },
      thirdStep: {
        title: "useCases.ePrescription.identityVerification.thirdStep.title",
        descriptionParagraphs:
          applicableLabels?.useCases?.ePrescription?.identityVerification
            ?.thirdStep?.descriptionParagraphs,
        button: {
          label:
            "useCases.ePrescription.identityVerification.thirdStep.buttonLabel",
        },
        imageBg:
          useCasesImages.ePrescription.identityVerification[0].thirdStep[0],
        imageBgStep:
          useCasesImages.ePrescription.identityVerification[0].thirdStep[1],
        imageBgGatacaLogo:
          useCasesImages.ePrescription.identityVerification[0].thirdStep[2],
      },
    },
    requestCredential: {
      id: "e-prescription",
      firstStep: {
        title: "useCases.ePrescription.requestCredential.firstStep.title",
        imageBg: useCasesImages.ePrescription.requestCredential[0].firstStep[0],
        imageBgStep:
          useCasesImages.ePrescription.requestCredential[0].firstStep[1],
        imageBgGatacaLogo:
          useCasesImages.ePrescription.requestCredential[0].firstStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.ePrescription?.requestCredential
            ?.firstStep?.descriptionParagraphs,
      },
      secondStep: {
        title: "useCases.ePrescription.requestCredential.secondStep.title",
        imageBg:
          useCasesImages.ePrescription.requestCredential[0].secondStep[0],
        imageBgStep:
          useCasesImages.ePrescription.requestCredential[0].secondStep[1],
        imageBgGatacaLogo:
          useCasesImages.ePrescription.requestCredential[0].secondStep[2],
        descriptionParagraphs:
          applicableLabels?.useCases?.ePrescription?.requestCredential
            ?.secondStep?.descriptionParagraphs,
      },
    },
    issuanceComplete: {
      title: "useCases.ePrescription.issuedCredential.title",
      button: {
        label: "useCases.ePrescription.issuedCredential.buttonLabel",
      },
      explanation: "useCases.ePrescription.issuedCredential.explanation",
      credentialComponent: [
        {
          icon: useCasesImages.ePrescription.issuedCredential
            .iconCredentialComponent,
          category: "useCases.ePrescription.issuedCredential.category",
          issuer: "useCases.ePrescription.issuedCredential.issuer",
        },
      ],
      imageBg: useCasesImages.ePrescription.issuedCredential.bgImages[0],
      imageBgStep: useCasesImages.ePrescription.issuedCredential.bgImages[1],
      imageBgGatacaLogo:
        useCasesImages.ePrescription.issuedCredential.bgImages[2],
      descriptionParagraphs:
        applicableLabels?.useCases?.ePrescription?.issuedCredential
          ?.descriptionParagraphs,
      descriptionParagraphsQR:
        applicableLabels?.useCases?.ePrescription?.issuedCredential
          ?.descriptionParagraphsQR,
    },
  },
];
