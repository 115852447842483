import { setSessionStorageData } from "../../services/sessionStorageService";
import {
  INavigate,
  ISetActiveRoute,
  NavigationActionsTypes,
} from "./navigationTypes";

// NAVIGATION
export const setSelectedRoute = (route: string): ISetActiveRoute => {
  setSessionStorageData("ga__route", route);
  return {
    type: NavigationActionsTypes.SET_ACTIVE_ROUTE,
    payload: {
      route: route,
    },
  };
};

export const navigateToAction = (path?: string): INavigate => {
  return {
    type: NavigationActionsTypes.NAVIGATE,
    path: path,
  };
};
