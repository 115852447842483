import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./receiveCredentials.module.scss";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setActiveUseCaseStep } from "../../../store/useCases/useCasesActions";
import { useCasesSelectors } from "../../../store/useCases";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../../store/navigation/navigationActions";
import { viewSteps } from "../data";
import { clearCredAndProcessState } from "../../../store/credentials/credentialActions";
import BackButton from "../../../components/atoms/buttons/back/BackButton";
import ChevronLeftSVG from "../../../assets/icons/ChevronLeftSVG";
import {
  deleteSessionStorageData,
  setSessionStorageData,
} from "../../../services/sessionStorageService";
import useSessionStorage from "../../../utils/useSessionStorage.hook";

const ReceiveCredentials: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stepsQty = viewSteps?.length;

  // Selectors
  const selectedUCSteps = useSelector(
    useCasesSelectors.getSelectedUseCaseSteps
  );
  const selectedUCPath = useSelector(useCasesSelectors.getSelectedUseCasePath);

  // States
  const [activeSubStepIndex, setActiveSubStepIndex] =
    useSessionStorage("ga__subStp");
  const activeStepC = viewSteps[activeSubStepIndex || 0]?.content;

  // Effects
  useEffect(() => {
    !activeSubStepIndex && setActiveSubStepIndex(0);
    dispatch(setSelectedRoute(`/demo/${selectedUCPath}/receive-credentials`));
    selectedUCSteps?.length &&
      selectedUCSteps[0] &&
      dispatch(setActiveUseCaseStep(selectedUCSteps[0]?.id));
  }, [selectedUCSteps]);

  // Functions
  const goToNextSection = () => {
    selectedUCSteps?.length &&
      selectedUCSteps[1] &&
      (setSessionStorageData("ga__subStp", "0"),
      deleteSessionStorageData("ga__process"),
      dispatch(clearCredAndProcessState()),
      dispatch(
        navigateToAction(`/demo/${selectedUCPath}/${selectedUCSteps[1]?.id}`)
      ));
  };

  const goToPreviousStep = () => {
    setSessionStorageData("ga__subStp", "0");
    activeSubStepIndex === 0
      ? (dispatch(navigateToAction(`/demo/${selectedUCPath}/download-wallet`)),
        dispatch(clearCredAndProcessState()))
      : setActiveSubStepIndex(0);
  };

  const manageSubStepChange = () => {
    activeSubStepIndex === stepsQty - 1
      ? goToNextSection()
      : setActiveSubStepIndex(activeSubStepIndex + 1);
  };

  return (
    <>
      <div className={`${styles?.receiveCredentials}`}>
        <div className={cx("gridWidth stepMaxWidth")}>
          <div className={cx("useCaseBackContainer")}>
            <BackButton
              label={t("backButton")}
              action={() => goToPreviousStep()}
              IconComponent={<ChevronLeftSVG />}
            />
          </div>
        </div>
        {activeStepC &&
          activeStepC(manageSubStepChange, goToNextSection, goToPreviousStep)}
      </div>
    </>
  );
};

export default ReceiveCredentials;
