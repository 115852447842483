// URLs/URIs
export const marketAndroidURI =
  "https://play.google.com/store/apps/details?id=com.gataca.identity";
export const marketIosURI = "https://apps.apple.com/us/app/gataca/id1498607616";
export const gatacaContact = "https://www.gataca.io/company/contact/";

export const currentRouteNeedSelectedUseCase = (activeRoute?: string) =>
  activeRoute !== "/demo/" &&
  activeRoute !== "/demo/home" &&
  !activeRoute?.includes("download-wallet") &&
  !activeRoute?.includes("finish");
