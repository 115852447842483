import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./identityVerification.module.scss";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useCasesSelectors } from "../../../store/useCases";
import { setActiveUseCaseStep } from "../../../store/useCases/useCasesActions";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../../store/navigation/navigationActions";
import { viewStepsForIssuanceUC, viewStepsForVerificationUC } from "../data";
import ChevronLeftSVG from "../../../assets/icons/ChevronLeftSVG";
import BackButton from "../../../components/atoms/buttons/back/BackButton";
import {
  deleteSessionStorageData,
  getSessionStorageData,
  setSessionStorageData,
} from "../../../services/sessionStorageService";
import useSessionStorage from "../../../utils/useSessionStorage.hook";
import { ProcessTypes } from "../../../data/useCasesData";
import { clearCredAndProcessState } from "../../../store/credentials/credentialActions";

const IdentityVerification: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Selectors
  const selectedUCType = useSelector(useCasesSelectors.getSelectedUseCaseType);
  const selectedUCSteps = useSelector(
    useCasesSelectors.getSelectedUseCaseSteps
  );
  const selectedUCPath = useSelector(useCasesSelectors.getSelectedUseCasePath);
  const firstCredSkipped = getSessionStorageData("ga__scredSkipped");

  // vars
  const viewSteps =
    selectedUCType === ProcessTypes.issuance
      ? viewStepsForIssuanceUC
      : viewStepsForVerificationUC;

  const stepsQty = viewSteps?.length;

  // States
  const [activeSubStepIndex, setActiveSubStepIndex] =
    useSessionStorage("ga__subStp");
  const activeStepC = viewSteps[activeSubStepIndex || 0]?.content;

  // Effects
  useEffect(() => {
    !activeSubStepIndex && setActiveSubStepIndex(0);
    dispatch(setSelectedRoute(`/demo/${selectedUCPath}/identity-verification`));

    selectedUCSteps?.length &&
      selectedUCSteps[1] &&
      dispatch(setActiveUseCaseStep(selectedUCSteps[1]?.id));
  }, [selectedUCSteps]);

  // Functions
  const goToNextSection = () => {
    selectedUCSteps?.length &&
      selectedUCSteps[2] &&
      (setSessionStorageData("ga__subStp", "0"),
      deleteSessionStorageData("ga__process"),
      dispatch(clearCredAndProcessState()),
      dispatch(
        navigateToAction(`/demo/${selectedUCPath}/${selectedUCSteps[2]?.id}`)
      ));
  };

  const goToPreviousStep = () => {
    selectedUCSteps?.length && selectedUCSteps[0] && activeSubStepIndex === 0
      ? (firstCredSkipped
          ? setSessionStorageData("ga__subStp", "0")
          : setSessionStorageData("ga__subStp", "2"),
        dispatch(
          navigateToAction(`/demo/${selectedUCPath}/${selectedUCSteps[0]?.id}`)
        ))
      : setActiveSubStepIndex(0);
  };

  const manageSubStepChange = () => {
    activeSubStepIndex === stepsQty - 1
      ? goToNextSection()
      : setActiveSubStepIndex(activeSubStepIndex + 1);
  };

  return (
    <>
      <div className={`${styles?.identityVerification}`}>
        <div className={cx("gridWidth stepMaxWidth")}>
          <div className={cx("useCaseBackContainer")}>
            <BackButton
              label={t("backButton")}
              action={() => goToPreviousStep()}
              IconComponent={<ChevronLeftSVG />}
            />
          </div>
        </div>
        {activeStepC && activeStepC(manageSubStepChange, goToNextSection)}
      </div>
    </>
  );
};

export default IdentityVerification;
