import logoGataca from "../assets/images/gataca-logo.svg";
import ellipseBackground from "../assets/images/ellipse-bg.svg";
import logoHomeBg from "../assets/images/logo-home-bg.svg";
import mobileCredentials from "../assets/images/mobile-credentials.png";
import tryDemoUseCasesEn from "../assets/images/try-demo_en.svg";
import tryDemoUseCasesEs from "../assets/images/try-demo_es.svg";
import mobile from "../assets/images/mobile.png";
import brandAppleButtonEs from "../assets/images/stores/app-store-es.svg";
import brandAppleButtonEn from "../assets/images/stores/app-store-en.svg";
import brandGoogleButtonEs from "../assets/images/stores/google-store-es.svg";
import brandGoogleButtonEn from "../assets/images/stores/google-store-en.svg";
import QrAppStore from "../assets/images/qr-app-store.png";
import bookACall from "../assets/images/book-a-call.png";

// Icons
import chevronLeft from "../assets/icons/gat-icon-chevron-left.svg";
import iconID from "../assets/icons/gat-icon-id.svg";
import iconBank from "../assets/icons/gat-icon-bank.svg";
import iconCheck from "../assets/icons/gat-icon-check.svg";
import iconSuccess from "../assets/icons/gat-illustration-success.svg";
import iconEventTicket from "../assets/icons/gat-icon-ticket.svg";
import iconAward from "../assets/icons/gat-icon-award.svg";
import iconKey from "../assets/icons/gat-icon-key.svg";
import iconTravel from "../assets/icons/gat-icon-travel.svg";
import iconHealth from "../assets/icons/gat-icon-health.svg";
import iconAtEmail from "../assets/icons/gat-icon-at.svg";

// Home Cards images
import openBankAccount__CardImg from "../assets/images/homeCards/openBankAccount.png";
import airportControl__CardImg from "../assets/images/homeCards/airportControl.png";
import boardingPass__CardImg from "../assets/images/homeCards/boardingPass.png";
import ePrescription__CardImg from "../assets/images/homeCards/ePrescription.png";
import eventAccess__CardImg from "../assets/images/homeCards/eventAccess.png";
import hotelCheckIn__CardImg from "../assets/images/homeCards/hotelCheckIn.png";
import obtainStudentId__CardImg from "../assets/images/homeCards/obtainStudentId.png";
import obtainVerifiableID__CardImg from "../assets/images/homeCards/obtainVerifiableID.png";

//Receive Credential images
import screenBankAccountBg from "../assets/images/receiveCredentials/screen-bank-account-bg.png";
import screenBankAccountNoLogoBg from "../assets/images/receiveCredentials/screen-bank-account-no-logo-bg.png";
import screenStudentIdBg from "../assets/images/receiveCredentials/screen-student-id-bg.png";
import screenStudentIdNoLogoBg from "../assets/images/receiveCredentials/screen-student-id-no-logo-bg.png";
import screenHotelCheckInBg from "../assets/images/receiveCredentials/screen-hotel-check-in-bg.png";
import screenHotelCheckInNoLogoBg from "../assets/images/receiveCredentials/screen-hotel-check-in-no-logo-bg.png";
import screenBoardingPassBg from "../assets/images/receiveCredentials/screen-boarding-pass-bg.png";
import screenBoardingPassNoLogoBg from "../assets/images/receiveCredentials/screen-boarding-pass-no-logo-bg.png";
import screenAirportControlBg from "../assets/images/receiveCredentials/screen-airport-control-bg.png";
import screenAirportControlNoLogoBg from "../assets/images/receiveCredentials/screen-airport-control-no-logo-bg.png";
import screenEventBg from "../assets/images/receiveCredentials/screen-event-bg.png";
import screenEventNoLogoBg from "../assets/images/receiveCredentials/screen-event-no-logo-bg.png";
import screenVerifiableIdBg from "../assets/images/receiveCredentials/screen-verifiable-id-bg.png";
import screenVerifiableIdNoLogoBg from "../assets/images/receiveCredentials/screen-verifiable-id-no-logo-bg.png";
import screenEprescriptionBg from "../assets/images/receiveCredentials/screen-eprescription-bg.png";
import screenEprescriptionNoLogoBg from "../assets/images/receiveCredentials/screen-eprescription-no-logo-bg.png";

// Identity Verification images
import openAccountBg from "../assets/images/identityVerification/open-account-bg.png";
import openAccountNoLogoBg from "../assets/images/identityVerification/open-account-no-logo-bg.png";
import openAccountEndBgEN from "../assets/images/identityVerification/open-account-bg-end_en.png";
import openAccountEndBgES from "../assets/images/identityVerification/open-account-bg-end_es.png";
import openAccountEndNoLogoBgEN from "../assets/images/identityVerification/open-account-bg-no-logo-end_en.png";
import openAccountEndNoLogoBgES from "../assets/images/identityVerification/open-account-bg-no-logo-end_es.png";
import accessPortalBg from "../assets/images/identityVerification/access-portal-bg.png";
import accessPortalNoLogoBg from "../assets/images/identityVerification/access-portal-no-logo-bg.png";
import accessPortalEndBgEN from "../assets/images/identityVerification/access-portal-end-bg_en.png";
import accessPortalEndBgES from "../assets/images/identityVerification/access-portal-end-bg_es.png";
import accessPortalEndNoLogoBgEN from "../assets/images/identityVerification/access-portal-end-no-logo-bg_en.png";
import accessPortalEndNoLogoBgES from "../assets/images/identityVerification/access-portal-end-no-logo-bg_es.png";
import checkInHotelBg from "../assets/images/identityVerification/check-in-hotel-bg.png";
import checkInHotelNoLogoBg from "../assets/images/identityVerification/check-in-hotel-no-logo-bg.png";
import checkInHotelEndBgEN from "../assets/images/identityVerification/check-in-hotel-end-bg_en.png";
import checkInHotelEndBgES from "../assets/images/identityVerification/check-in-hotel-end-bg_es.png";
import checkInHotelEndNoLogoBgEN from "../assets/images/identityVerification/check-in-hotel-end-no-logo-bg_en.png";
import checkInHotelEndNoLogoBgES from "../assets/images/identityVerification/check-in-hotel-end-no-logo-bg_es.png";
import accessAirlineAccountBg from "../assets/images/identityVerification/access-airline-account-bg.png";
import accessAirlineAccountNoLogoBg from "../assets/images/identityVerification/access-airline-account-no-logo-bg.png";
import accessAirlineAccountEndBgEN from "../assets/images/identityVerification/access-airline-account-end-bg_en.png";
import accessAirlineAccountEndBgES from "../assets/images/identityVerification/access-airline-account-end-bg_es.png";
import accessAirlineAccountEndNoLogoBgEN from "../assets/images/identityVerification/access-airline-account-end-no-logo-bg_en.png";
import accessAirlineAccountEndNoLogoBgES from "../assets/images/identityVerification/access-airline-account-end-no-logo-bg_es.png";
import airportControlBg from "../assets/images/identityVerification/airport-control-bg.png";
import airportControlNoLogoBg from "../assets/images/identityVerification/airport-control-no-logo-bg.png";
import eventBg from "../assets/images/identityVerification/event-bg.png";
import eventNoLogoBg from "../assets/images/identityVerification/event-no-logo-bg.png";
import verifiableIdBg from "../assets/images/identityVerification/verifiable-id-bg.png";
import verifiableIdNoLogoBg from "../assets/images/identityVerification/verifiable-id-no-logo-bg.png";
import verifiableIdEndBgEN from "../assets/images/identityVerification/verifiable-id-end-bg_en.png";
import verifiableIdEndBgES from "../assets/images/identityVerification/verifiable-id-end-bg_es.png";
import verifiableIdEndNoLogoBgEN from "../assets/images/identityVerification/verifiable-id-end-no-logo-bg_en.png";
import verifiableIdEndNoLogoBgES from "../assets/images/identityVerification/verifiable-id-end-no-logo-bg_es.png";
import healthcarePlatformBg from "../assets/images/identityVerification/healthcare-platform-bg.png";
import healthcarePlatformNoLogoBg from "../assets/images/identityVerification/healthcare-platform-no-logo-bg.png";
import healthcarePlatformEndBgEN from "../assets/images/identityVerification/healthcare-platform-end-bg_en.png";
import healthcarePlatformEndBgES from "../assets/images/identityVerification/healthcare-platform-end-bg_es.png";
import healthcarePlatformEndNoLogoBgEN from "../assets/images/identityVerification/healthcare-platform-end-no-logo-bg_en.png";
import healthcarePlatformEndNoLogoBgES from "../assets/images/identityVerification/healthcare-platform-end-no-logo-bg_es.png";

// Request Credential images
import requestBankAccountBg from "../assets/images/requestCredential/request-bank-account-bg.png";
import requestBankAccountNoLogoBg from "../assets/images/requestCredential/request-bank-account-no-logo-bg.png";
import requestStudentIdBg from "../assets/images/requestCredential/request-student-id-bg.png";
import requestStudentIdNoLogoBg from "../assets/images/requestCredential/request-student-id-no-logo-bg.png";
import requestRoomKeyBg from "../assets/images/requestCredential/request-room-key-bg.png";
import requestRoomKeyNoLogoBg from "../assets/images/requestCredential/request-room-key-no-logo-bg.png";
import requestBoardingPassBg from "../assets/images/requestCredential/request-boarding-pass-bg.png";
import requestBoardingPassNoLogoBg from "../assets/images/requestCredential/request-boarding-pass-no-logo-bg.png";
import requestVerifiableIdBg from "../assets/images/requestCredential/request-verifiable-id-bg.png";
import requestVerifiableIdNoLogoBg from "../assets/images/requestCredential/request-verifiable-id-no-logo-bg.png";
import requestEprescriptionBg from "../assets/images/requestCredential/request-eprescription-bg.png";
import requestEprescriptionNoLogoBg from "../assets/images/requestCredential/request-eprescription-no-logo-bg.png";

// Issued Credential
import issuedBankAccountEndBgEN from "../assets/images/issuedCredential/issued-bank-account-end-bg_en.png";
import issuedBankAccountEndBgES from "../assets/images/issuedCredential/issued-bank-account-end-bg_es.png";
import issuedBankAccountEndNoLogoBgEN from "../assets/images/issuedCredential/issued-bank-account-end-no-logo-bg_en.png";
import issuedBankAccountEndNoLogoBgES from "../assets/images/issuedCredential/issued-bank-account-end-no-logo-bg_es.png";
import issuedStudentIdEndBgEN from "../assets/images/issuedCredential/issued-student-id-end-bg_en.png";
import issuedStudentIdEndBgES from "../assets/images/issuedCredential/issued-student-id-end-bg_es.png";
import issuedStudentIdEndNoLogoBgEN from "../assets/images/issuedCredential/issued-student-id-end-no-logo-bg_en.png";
import issuedStudentIdEndNoLogoBgES from "../assets/images/issuedCredential/issued-student-id-end-no-logo-bg_es.png";
import issuedRoomKeyEndBgEN from "../assets/images/issuedCredential/issued-room-key-end-bg_en.png";
import issuedRoomKeyEndBgES from "../assets/images/issuedCredential/issued-room-key-end-bg_es.png";
import issuedRoomKeyEndNoLogoBgEN from "../assets/images/issuedCredential/issued-room-key-end-no-logo-bg_en.png";
import issuedRoomKeyEndNoLogoBgES from "../assets/images/issuedCredential/issued-room-key-end-no-logo-bg_es.png";
import issuedBoardingPassEndBgEN from "../assets/images/issuedCredential/issued-boarding-pass-end-bg_en.png";
import issuedBoardingPassEndBgES from "../assets/images/issuedCredential/issued-boarding-pass-end-bg_es.png";
import issuedBoardingPassEndNoLogoBgEN from "../assets/images/issuedCredential/issued-boarding-pass-end-no-logo-bg_en.png";
import issuedBoardingPassEndNoLogoBgES from "../assets/images/issuedCredential/issued-boarding-pass-end-no-logo-bg_es.png";
import issuedVerifiableIdEndBgEN from "../assets/images/issuedCredential/issued-verifiable-id-end-bg_en.png";
import issuedVerifiableIdEndBgES from "../assets/images/issuedCredential/issued-verifiable-id-end-bg_es.png";
import issuedVerifiableIdEndNoLogoBgEN from "../assets/images/issuedCredential/issued-verifiable-id-end-no-logo-bg_en.png";
import issuedVerifiableIdEndNoLogoBgES from "../assets/images/issuedCredential/issued-verifiable-id-end-no-logo-bg_es.png";
import issuedEprescriptionEndBgEN from "../assets/images/issuedCredential/issued-eprescription-end-bg_en.png";
import issuedEprescriptionEndBgES from "../assets/images/issuedCredential/issued-eprescription-end-bg_es.png";
import issuedEprescriptionEndNoLogoBgEN from "../assets/images/issuedCredential/issued-eprescription-end-no-logo-bg_en.png";
import issuedEprescriptionEndNoLogoBgES from "../assets/images/issuedCredential/issued-eprescription-end-no-logo-bg_es.png";

// Get Access
import accessAirportControlEndBgEN from "../assets/images/getAccess/access-airport-control-end-bg_en.png";
import accessAirportControlEndBgES from "../assets/images/getAccess/access-airport-control-end-bg_es.png";
import accessAirportControlEndNoLogoBgEN from "../assets/images/getAccess/access-airport-control-end-no-logo-bg_en.png";
import accessAirportControlEndNoLogoBgES from "../assets/images/getAccess/access-airport-control-end-no-logo-bg_es.png";
import accessEventEndBgEN from "../assets/images/getAccess/access-event-end-bg_en.png";
import accessEventEndBgES from "../assets/images/getAccess/access-event-end-bg_es.png";
import accessEventEndNoLogoBgEN from "../assets/images/getAccess/access-event-end-no-logo-bg_en.png";
import accessEventEndNoLogoBgES from "../assets/images/getAccess/access-event-end-no-logo-bg_es.png";

//QR code images
import generalBankIcon from "../assets/icons/qrIcons/general-bank.svg";
import eventOrganizationIcon from "../assets/icons/qrIcons/event-organization.svg";
import governmentIcon from "../assets/icons/qrIcons/government.svg";
import healthcareIcon from "../assets/icons/qrIcons/healthcare.svg";
import hotelIcon from "../assets/icons/qrIcons/hotel.svg";
import nationalPoliceIcon from "../assets/icons/qrIcons/national-police.svg";
import onlineAirlineIcon from "../assets/icons/qrIcons/online-airline.svg";
import universityIcon from "../assets/icons/qrIcons/university.svg";
import gatacaIcon from "../assets/icons/qrIcons/gataca.svg";
import logoFullBg from "../assets/images/logo-use-cases-bg.png";
import { initialLang } from "../i18n";

export const gaImages = {
  logoGataca: { src: logoGataca, alt: "images.gatacaLogo" },
  ellipseBackground: { src: ellipseBackground, alt: "" },
  logoHomeBg: { src: logoHomeBg, alt: "" },
  mobileCredentials: {
    src: mobileCredentials,
    alt: "images.walletCredentials",
  },
  tryDemoUseCases: {
    src: initialLang === "es" ? tryDemoUseCasesEs : tryDemoUseCasesEn,
    alt: "images.tryDemoUseCases",
  },
  mobile: { src: mobile, alt: "images.tryDemoUseCases" },
  brandAppleButton: {
    src: initialLang === "es" ? brandAppleButtonEs : brandAppleButtonEn,
    alt: "images.brandAppleButton",
  },
  brandGoogleButton: {
    src: initialLang === "es" ? brandGoogleButtonEs : brandGoogleButtonEn,
    alt: "images.brandGoogleButton",
  },
  QrAppStore: {
    src: QrAppStore,
    alt: "images.QrAppStore",
  },
  bookACall: {
    src: bookACall,
    alt: "",
  },
};

export const gaIcons = {
  chevronLeft: { src: chevronLeft, alt: "" },
  iconSuccess: { src: iconSuccess, alt: "" },
  iconCheck: { src: iconCheck, alt: "" },
};

export const gaQRIcons = {
  gataca: gatacaIcon,
  generalBank: generalBankIcon,
  eventOrganization: eventOrganizationIcon,
  government: governmentIcon,
  healthcare: healthcareIcon,
  hotel: hotelIcon,
  nationalPolice: nationalPoliceIcon,
  onlineAirline: onlineAirlineIcon,
  university: universityIcon,
};

export const verifiableIdIcons = {
  iconID: { src: iconID, alt: "" },
  event: { src: iconEventTicket, alt: "" },
  verifiableId: { src: iconAward, alt: "" },
};

export const useCasesImages = {
  openBankAccount: {
    homeCard: {
      src: openBankAccount__CardImg,
      alt: "useCases.openBankAccount.homeCard.imgAlt",
    },
    receiveCredentials: [
      {
        firstStep: [
          {
            src: screenBankAccountBg,
            alt: "",
          },
          { src: screenBankAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: screenBankAccountBg,
            alt: "",
          },
          { src: screenBankAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: screenBankAccountBg,
            alt: "",
          },
          { src: screenBankAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        iconCredentialComponent: { src: iconID, alt: "" },
      },
    ],
    identityVerification: [
      {
        firstStep: [
          {
            src: openAccountBg,
            alt: "",
          },
          { src: openAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: openAccountBg,
            alt: "",
          },
          { src: openAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: initialLang === "es" ? openAccountEndBgES : openAccountEndBgEN,
            alt: "",
          },
          {
            src:
              initialLang === "es"
                ? openAccountEndNoLogoBgES
                : openAccountEndNoLogoBgEN,
            alt: "",
          },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    requestCredential: [
      {
        firstStep: [
          {
            src: requestBankAccountBg,
            alt: "",
          },
          { src: requestBankAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: requestBankAccountBg,
            alt: "",
          },
          { src: requestBankAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    issuedCredential: {
      bgImages: [
        {
          src:
            initialLang === "es"
              ? issuedBankAccountEndBgES
              : issuedBankAccountEndBgEN,
          alt: "",
        },
        {
          src:
            initialLang === "es"
              ? issuedBankAccountEndNoLogoBgES
              : issuedBankAccountEndNoLogoBgEN,
          alt: "",
        },
        { src: logoFullBg, alt: "Logo Gataca" },
      ],
      iconCredentialComponent: { src: iconBank, alt: "" },
    },
  },
  airportControl: {
    homeCard: {
      src: airportControl__CardImg,
      alt: "useCases.airportControl.homeCard.imgAlt",
    },
    receiveCredentials: [
      {
        firstStep: [
          {
            src: screenAirportControlBg,
            alt: "",
          },
          { src: screenAirportControlNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: screenAirportControlBg,
            alt: "",
          },
          { src: screenAirportControlNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: screenAirportControlBg,
            alt: "",
          },
          { src: screenAirportControlNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        iconCredentialComponent: { src: iconID, alt: "" },
      },
    ],
    identityVerification: [
      {
        firstStep: [
          {
            src: airportControlBg,
            alt: "",
          },
          { src: airportControlNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: openAccountBg,
            alt: "",
          },
          { src: airportControlNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    getAccess: {
      bgImages: [
        {
          src:
            initialLang === "es"
              ? accessAirportControlEndBgES
              : accessAirportControlEndBgEN,
          alt: "",
        },
        {
          src:
            initialLang === "es"
              ? accessAirportControlEndNoLogoBgES
              : accessAirportControlEndNoLogoBgEN,
          alt: "",
        },
        { src: logoFullBg, alt: "Logo Gataca" },
      ],
    },
  },
  boardingPass: {
    homeCard: {
      src: boardingPass__CardImg,
      alt: "useCases.boardingPass.homeCard.imgAlt",
    },
    receiveCredentials: [
      {
        firstStep: [
          {
            src: screenBoardingPassBg,
            alt: "",
          },
          { src: screenBoardingPassNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: screenBoardingPassBg,
            alt: "",
          },
          { src: screenBoardingPassNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: screenBoardingPassBg,
            alt: "",
          },
          { src: screenBoardingPassNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        iconCredentialComponentFirst: { src: iconID, alt: "" },
        iconCredentialComponentSecond: { src: iconAtEmail, alt: "" },
      },
    ],
    identityVerification: [
      {
        firstStep: [
          {
            src: accessAirlineAccountBg,
            alt: "",
          },
          { src: accessAirlineAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: accessAirlineAccountBg,
            alt: "",
          },
          { src: accessAirlineAccountNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src:
              initialLang === "es"
                ? accessAirlineAccountEndBgES
                : accessAirlineAccountEndBgEN,
            alt: "",
          },
          {
            src:
              initialLang === "es"
                ? accessAirlineAccountEndNoLogoBgES
                : accessAirlineAccountEndNoLogoBgEN,
            alt: "",
          },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    requestCredential: [
      {
        firstStep: [
          {
            src: requestBoardingPassBg,
            alt: "",
          },
          { src: requestBoardingPassNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: requestBoardingPassBg,
            alt: "",
          },
          { src: requestBoardingPassNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    issuedCredential: {
      bgImages: [
        {
          src:
            initialLang === "es"
              ? issuedBoardingPassEndBgES
              : issuedBoardingPassEndBgEN,
          alt: "",
        },
        {
          src:
            initialLang === "es"
              ? issuedBoardingPassEndNoLogoBgES
              : issuedBoardingPassEndNoLogoBgEN,
          alt: "",
        },
        { src: logoFullBg, alt: "Logo Gataca" },
      ],
      iconCredentialComponent: { src: iconTravel, alt: "" },
    },
  },
  ePrescription: {
    homeCard: {
      src: ePrescription__CardImg,
      alt: "useCases.ePrescription.homeCard.imgAlt",
    },
    receiveCredentials: [
      {
        firstStep: [
          {
            src: screenEprescriptionBg,
            alt: "",
          },
          { src: screenEprescriptionNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: screenEprescriptionBg,
            alt: "",
          },
          { src: screenEprescriptionNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: screenEprescriptionBg,
            alt: "",
          },
          { src: screenEprescriptionNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        iconCredentialComponent: { src: iconID, alt: "" },
      },
    ],
    identityVerification: [
      {
        firstStep: [
          {
            src: healthcarePlatformBg,
            alt: "",
          },
          { src: healthcarePlatformNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: healthcarePlatformBg,
            alt: "",
          },
          { src: healthcarePlatformNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src:
              initialLang === "es"
                ? healthcarePlatformEndBgES
                : healthcarePlatformEndBgEN,
            alt: "",
          },
          {
            src:
              initialLang === "es"
                ? healthcarePlatformEndNoLogoBgES
                : healthcarePlatformEndNoLogoBgEN,
            alt: "",
          },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    requestCredential: [
      {
        firstStep: [
          {
            src: requestEprescriptionBg,
            alt: "",
          },
          { src: requestEprescriptionNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: requestEprescriptionBg,
            alt: "",
          },
          { src: requestEprescriptionNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    issuedCredential: {
      bgImages: [
        {
          src:
            initialLang === "es"
              ? issuedEprescriptionEndBgES
              : issuedEprescriptionEndBgEN,
          alt: "",
        },
        {
          src:
            initialLang === "es"
              ? issuedEprescriptionEndNoLogoBgES
              : issuedEprescriptionEndNoLogoBgEN,
          alt: "",
        },
        { src: logoFullBg, alt: "Logo Gataca" },
      ],
      iconCredentialComponent: { src: iconHealth, alt: "" },
    },
  },
  eventAccess: {
    homeCard: {
      src: eventAccess__CardImg,
      alt: "useCases.eventAccess.homeCard.imgAlt",
    },
    receiveCredentials: [
      {
        firstStep: [
          {
            src: screenEventBg,
            alt: "",
          },
          { src: screenEventNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: screenEventBg,
            alt: "",
          },
          { src: screenEventNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: screenEventBg,
            alt: "",
          },
          { src: screenEventNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        iconCredentialComponent: { src: iconEventTicket, alt: "" },
      },
    ],
    identityVerification: [
      {
        firstStep: [
          {
            src: eventBg,
            alt: "",
          },
          { src: eventNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: eventBg,
            alt: "",
          },
          { src: eventNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src:
              initialLang === "es"
                ? healthcarePlatformEndBgES
                : healthcarePlatformEndBgEN,
            alt: "",
          },
          {
            src:
              initialLang === "es"
                ? healthcarePlatformEndNoLogoBgES
                : healthcarePlatformEndNoLogoBgEN,
            alt: "",
          },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    getAccess: {
      bgImages: [
        {
          src: initialLang === "es" ? accessEventEndBgES : accessEventEndBgEN,
          alt: "",
        },
        {
          src:
            initialLang === "es"
              ? accessEventEndNoLogoBgES
              : accessEventEndNoLogoBgEN,
          alt: "",
        },
        { src: logoFullBg, alt: "Logo Gataca" },
      ],
    },
  },
  hotelCheckIn: {
    homeCard: {
      src: hotelCheckIn__CardImg,
      alt: "useCases.hotelCheckIn.homeCard.imgAlt",
    },
    receiveCredentials: [
      {
        firstStep: [
          {
            src: screenHotelCheckInBg,
            alt: "",
          },
          { src: screenHotelCheckInNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: screenHotelCheckInBg,
            alt: "",
          },
          { src: screenHotelCheckInNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: screenHotelCheckInBg,
            alt: "",
          },
          { src: screenHotelCheckInNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        iconCredentialComponent: { src: iconID, alt: "" },
      },
    ],
    identityVerification: [
      {
        firstStep: [
          {
            src: checkInHotelBg,
            alt: "",
          },
          { src: checkInHotelNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: checkInHotelBg,
            alt: "",
          },
          { src: checkInHotelNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src:
              initialLang === "es" ? checkInHotelEndBgES : checkInHotelEndBgEN,
            alt: "",
          },
          {
            src:
              initialLang === "es"
                ? checkInHotelEndNoLogoBgES
                : checkInHotelEndNoLogoBgEN,
            alt: "",
          },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    requestCredential: [
      {
        firstStep: [
          {
            src: requestRoomKeyBg,
            alt: "",
          },
          { src: requestRoomKeyNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: requestRoomKeyBg,
            alt: "",
          },
          { src: requestRoomKeyNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    issuedCredential: {
      bgImages: [
        {
          src:
            initialLang === "es" ? issuedRoomKeyEndBgES : issuedRoomKeyEndBgEN,
          alt: "",
        },
        {
          src:
            initialLang === "es"
              ? issuedRoomKeyEndNoLogoBgES
              : issuedRoomKeyEndNoLogoBgEN,
          alt: "",
        },
        { src: logoFullBg, alt: "Logo Gataca" },
      ],
      iconCredentialComponent: { src: iconKey, alt: "" },
    },
  },
  obtainStudentId: {
    homeCard: {
      src: obtainStudentId__CardImg,
      alt: "useCases.obtainStudentId.homeCard.imgAlt",
    },
    receiveCredentials: [
      {
        firstStep: [
          {
            src: screenStudentIdBg,
            alt: "",
          },
          { src: screenStudentIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: screenStudentIdBg,
            alt: "",
          },
          { src: screenStudentIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: screenStudentIdBg,
            alt: "",
          },
          { src: screenStudentIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        iconCredentialComponent: { src: iconID, alt: "" },
      },
    ],
    identityVerification: [
      {
        firstStep: [
          {
            src: accessPortalBg,
            alt: "",
          },
          { src: accessPortalNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: accessPortalBg,
            alt: "",
          },
          { src: accessPortalNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src:
              initialLang === "es" ? accessPortalEndBgES : accessPortalEndBgEN,
            alt: "",
          },
          {
            src:
              initialLang === "es"
                ? accessPortalEndNoLogoBgES
                : accessPortalEndNoLogoBgEN,
            alt: "",
          },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    requestCredential: [
      {
        firstStep: [
          {
            src: requestStudentIdBg,
            alt: "",
          },
          { src: requestStudentIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: requestStudentIdBg,
            alt: "",
          },
          { src: requestStudentIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    issuedCredential: {
      bgImages: [
        {
          src:
            initialLang === "es"
              ? issuedStudentIdEndBgES
              : issuedStudentIdEndBgEN,
          alt: "",
        },
        {
          src:
            initialLang === "es"
              ? issuedStudentIdEndNoLogoBgES
              : issuedStudentIdEndNoLogoBgEN,
          alt: "",
        },
        { src: logoFullBg, alt: "Logo Gataca" },
      ],
      iconCredentialComponent: { src: iconID, alt: "" },
    },
  },
  obtainVerifiableID: {
    homeCard: {
      src: obtainVerifiableID__CardImg,
      alt: "useCases.obtainVerifiableID.homeCard.imgAlt",
    },
    receiveCredentials: [
      {
        firstStep: [
          {
            src: screenVerifiableIdBg,
            alt: "",
          },
          { src: screenVerifiableIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: screenVerifiableIdBg,
            alt: "",
          },
          { src: screenVerifiableIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src: screenVerifiableIdBg,
            alt: "",
          },
          { src: screenVerifiableIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        iconCredentialComponent: { src: iconAward, alt: "" },
      },
    ],
    identityVerification: [
      {
        firstStep: [
          {
            src: verifiableIdBg,
            alt: "",
          },
          { src: verifiableIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: verifiableIdBg,
            alt: "",
          },
          { src: verifiableIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        thirdStep: [
          {
            src:
              initialLang === "es" ? verifiableIdEndBgES : verifiableIdEndBgEN,
            alt: "",
          },
          {
            src:
              initialLang === "es"
                ? verifiableIdEndNoLogoBgES
                : verifiableIdEndNoLogoBgEN,
            alt: "",
          },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    requestCredential: [
      {
        firstStep: [
          {
            src: requestVerifiableIdBg,
            alt: "",
          },
          { src: requestVerifiableIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
        secondStep: [
          {
            src: requestVerifiableIdBg,
            alt: "",
          },
          { src: requestVerifiableIdNoLogoBg, alt: "" },
          { src: logoFullBg, alt: "Logo Gataca" },
        ],
      },
    ],
    issuedCredential: {
      bgImages: [
        {
          src:
            initialLang === "es"
              ? issuedVerifiableIdEndBgES
              : issuedVerifiableIdEndBgEN,
          alt: "",
        },
        {
          src:
            initialLang === "es"
              ? issuedVerifiableIdEndNoLogoBgES
              : issuedVerifiableIdEndNoLogoBgEN,
          alt: "",
        },
        { src: logoFullBg, alt: "Logo Gataca" },
      ],
      iconCredentialComponent: { src: iconID, alt: "" },
    },
  },
};
