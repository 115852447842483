export interface ISetSelectedUseCase {
  type: UseCasesActionsTypes.SET_SELECTED_USE_CASE;
  payload: {
    id: string | undefined;
  };
}

export interface ISetActiveUseCaseStep {
  type: UseCasesActionsTypes.SET_ACTIVE_USE_CASE_STEP;
  payload: {
    id: string | undefined;
  };
}

export type UseCasesActions = ISetSelectedUseCase | any;

export enum UseCasesActionsTypes {
  SET_SELECTED_USE_CASE = "usecases/set_selected_use_case",
  SET_ACTIVE_USE_CASE_STEP = "usecases/set_active_use_case_step",
}

export interface IScafoldingState {
  selectedUseCase?: string;
  activeUseCaseStep?: string;
}
