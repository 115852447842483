import * as React from "react";
import styles from "./stepperProgressBar.module.scss";
import cx from "classnames";
import { t } from "i18next";
import { FlowStepModel } from "../../../../interfaces/interfaces";

type StepperProgressBarProps = {
  steps?: (FlowStepModel | undefined)[];
  activeStep: number;
  children?: React.ReactNode;
};

const StepperProgressBar: React.FC<StepperProgressBarProps> = (props) => {
  const { steps, activeStep } = props;
  const stepCompleted = (index: number) => activeStep > index;
  const stepActive = (index: number) => activeStep === index;

  return (
    <div
      className={cx(
        styles.progressBar_container,
        styles.col_centered,
        steps && steps?.length < 4 ? styles.short_container : undefined
      )}>
      <ul className={cx(styles.progressbar)}>
        {steps?.map((step, index) => {
          return (
            <li
              key={"step__" + index}
              className={cx(
                stepCompleted(index) && styles.completed,
                stepActive(index) && styles.active,
                stepActive(index) && styles.uncompleted,
                stepCompleted(index) || stepActive(index)
                  ? "buttonSM"
                  : "bodyRegularSM neutral700"
              )}>
              {step?.text && (
                <div className={cx(styles.progress_text)}>{t(step?.text)}</div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default StepperProgressBar;
