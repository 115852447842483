import React, { useEffect } from "react";
import styles from "./flowFinishElements.module.scss";
import cx from "classnames";
import { useDispatch } from "react-redux";
import {
  navigateToAction,
  setSelectedRoute,
} from "../../../store/navigation/navigationActions";
import { ButtonModel, ImageModel } from "../../../interfaces/interfaces";
import GaImage from "../../../components/atoms/Images/gaImage";
import PurpleButton from "../../../components/atoms/buttons/purple/PurpleButton";
import TransparentButton from "../../../components/atoms/buttons/transparent/TransparentButton";

export type IFlowFinishElementsProps = {
  image: ImageModel;
  title: string;
  description: string;
  contactButton: ButtonModel;
  homeButton: ButtonModel;
};

const FlowFinishElements: React.FC<IFlowFinishElementsProps> = (props) => {
  const { image, title, description, contactButton, homeButton } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: LOCK BACK BUTTON IN THIS VIEW?
    dispatch(setSelectedRoute("/finish"));
  }, []);

  // Functions
  const goHome = () => {
    dispatch(navigateToAction("/"));
  };

  return (
    <>
      <div className={`${styles?.flowFinishElements} `}>
        <div className={`${styles?.leftColumn} `}>
          <h1 className={`${cx("marginBottom20")}`}>{title}</h1>
          <p>{description}</p>
          <PurpleButton
            className={`${contactButton?.className}`}
            label={contactButton?.label}
            action={contactButton?.action}
            size={contactButton?.size}
          />
          <TransparentButton
            className={`${homeButton?.className} ${cx("marginTop20")}`}
            label={homeButton?.label}
            action={homeButton?.action}
            size={homeButton?.size}
          />
        </div>
        <div className={`${styles?.rightColumn} `}>
          <GaImage image={image}></GaImage>
        </div>
      </div>
    </>
  );
};

export default FlowFinishElements;
